import { memo } from "react";

// SimpleNode
// ----------------------------------------------------------------------------

function SimpleNode(node) {
  return <></>;
}

export default memo(SimpleNode);
