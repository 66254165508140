// Utils
import URLs from "../../urls/URLs";

// URLs
import EdgeApiURL from "../../urls/api-urls/graph/EdgeApiURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

//
// API Functions
// ----------------------------------------------------------------------------

// Create Edge
const createEdge = async function (graphId, edgeInfo) {
  // API URL
  const apiURL = URLs.format(EdgeApiURL.CreateEdgeURL, { graphId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, edgeInfo);
};

// Update Edge Info
const updateEdgeInfo = async function (graphId, edgeUid, edgeInfo) {
  // Params
  const params = {
    type: edgeInfo.type,
  };

  // API URL
  const apiURL = URLs.format(EdgeApiURL.UpdateEdgeInfoByUidURL, { graphId, edgeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, params);
};

// Update Edge Var Info
const updateEdgeVarInfo = async function (graphId, edgeUid, varInfo) {
  // Params
  const params = {
    varType: varInfo.varType,
    name: varInfo.name,
    symbol: varInfo.symbol,
  };

  // API URL
  const apiURL = URLs.format(EdgeApiURL.UpdateEdgeVarInfoByUidURL, { graphId, edgeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, params);
};

// Update Edge Style
const updateEdgeStyle = async function (graphId, edgeUid, styleObj) {
  // API URL
  const apiURL = URLs.format(EdgeApiURL.UpdateEdgeStyleByUidURL, { graphId, edgeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, {}, styleObj);
};

// Delete Edge
const deleteEdge = async function (graphId, edgeUid) {
  // API URL
  const apiURL = URLs.format(EdgeApiURL.DeleteEdgeByUidURL, { graphId, edgeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiURL);
};

// Export
// ----------------------------------------------------------------------------

const EdgeAPI = {
  //
  createEdge,

  //
  updateEdgeInfo,
  updateEdgeVarInfo,
  updateEdgeStyle,

  //
  deleteEdge,
};

export default EdgeAPI;
