// Graph Edge API URLs

//
const EdgeApiURL = {
  //
  CreateEdgeURL: "/api/v1/graph/:graphId/edge",

  //
  UpdateEdgeInfoByUidURL: "/api/v1/graph/:graphId/edge/:edgeUid",
  UpdateEdgeVarInfoByUidURL: "/api/v1/graph/:graphId/edge/:edgeUid/var",
  UpdateEdgeStyleByUidURL: "/api/v1/graph/:graphId/edge/:edgeUid/style",

  //
  DeleteEdgeByUidURL: "/api/v1/graph/:graphId/edge/:edgeUid",

  //
};

// Export
export default EdgeApiURL;
