// Components
import InputWithMentions from "../input-with-mentions/InputWithMentions";

// Regex
const symbolRegex = new RegExp("^\\s*([+\\-*\\/\\^\\(\\)\\[\\]{}|?!.:;,'#%&=<>~_$@`^]+|\\s*)\\s*$");

export default function ExpressionInput({
  value = "",
  suggestions = [],
  onChange = () => {},
  placeholder = "Enter Expression...",
  containerClassName = "",
}) {
  // For empty string suggestions are not shown
  const endValue = value.slice(-1)?.trim() || "";
  const trigger = symbolRegex.test(endValue) ? "@" : endValue;

  return (
    <>
      {/* Input With Mentions */}
      <InputWithMentions
        value={value}
        trigger={trigger}
        suggestions={suggestions}
        onChange={onChange}
        placeholder={placeholder}
        appendSpaceOnAdd={true}
        containerClassName={containerClassName}
      />
    </>
  );
}
