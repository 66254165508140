// Utils
import URLs from "../../urls/URLs";

// URLs
import ProjectApiURL from "../../urls/api-urls/project/ProjectApiURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

import { DefaultPagination } from "../../constants/WebConstants";

//
// API Functions
// ----------------------------------------------------------------------------

// Search Projects
const searchProjects = async (searchText, pageNumber, pageSize) => {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  // API URL
  const apiURL = URLs.format(ProjectApiURL.SearchProjectsURL);

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL, params);
};

// Export
// ----------------------------------------------------------------------------

const ProjectListAPI = {
  //
  searchProjects,
};

export default ProjectListAPI;
