//
import { Link } from "react-router-dom";

import NavBranding from "../../app/layout/NavBranding";

import URLs from "../../urls/URLs";
import PageURL from "../../urls/PageURL";

import URL_IMG_LOGO from "../../assests/imgs/branding/logo.png";

//
// Component
// ----------------------------------------------------------------------------

// Main
const ProjectTopbar = ({ projectInfo, mode, validationLoading = false, dispatchGraphValidation }) => {
  // URLs
  const projectId = projectInfo.id;
  const projectCanvasPageURL = URLs.format(PageURL.ProjectCanvasPageURL, { projectId });
  const projectSourcePageURL = URLs.format(PageURL.ProjectSourcePageURL, { projectId });
  const projectOptPageURL = URLs.format(PageURL.ProjectOptListPageURL, { projectId });

  //
  return (
    <header className="topbar">
      <div className="d-inline-block">
        <NavBranding className="left border-end" logoURL={URL_IMG_LOGO} redirectURL={PageURL.DashboardPageURL} />
      </div>
      <div className="d-inline-block w-100 h-100">
        <div className="d-flex justify-content-between">
          {/** */}
          <div className="py-3">
            <h5 className="pt-1 ps-4">{projectInfo.name}</h5>
          </div>

          {/** Middle Menu */}
          <div className="mt-1 py-2">
            <div className="btn-group" role="group" aria-label="Basic example">
              {/** Graph Canvas Page */}
              <Link
                className={`btn btn-outline-secondary ${mode === "canvas" ? "active" : ""}`}
                to={projectCanvasPageURL}
              >
                <i className="fas fa-vector-square me-1"></i> Build
              </Link>

              {/** Graph Simulation Page */}
              <Link className={`btn btn-outline-secondary ${mode !== "canvas" ? "active" : ""}`} to={projectOptPageURL}>
                <i className="fas fa-play me-1"></i> Execute
              </Link>
            </div>
          </div>
          <div className="mt-1 py-2 me-3" style={{ minWidth: "260px" }}>
            <div className={`mt-1 ${mode === "canvas" ? "" : "d-none"}`}>
              <button className="btn btn-sm btn-outline-primary" onClick={() => dispatchGraphValidation({})}>
                Validate Graph
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

//
// Export
// ----------------------------------------------------------------------------

export default ProjectTopbar;
