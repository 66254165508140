import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

// Components
import TableDataNotFound from "../../components/table/TableDataNotFound";
import TableHeaders from "../../components/table/TableHeaders";
import TableLoader from "../../components/table/TableLoader";

// App Utilities
import URLs from "../../urls/URLs";
import PageURL from "../../urls/PageURL";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "S.No.",
    },
  },
  {
    title: {
      displayName: "Project Name",
    },
  },
  {
    title: {
      displayName: "Created",
    },
  },
  {
    title: {
      displayName: "Modified",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function ProjectListTableRow({ serialNo, project = {} }) {
  // Data
  const { id = "", name = "", description = "", createdOn = "", updatedOn = "" } = project;
  const ProjectCanvasURL = URLs.format(PageURL.ProjectCanvasPageURL, { projectId: id });

  //
  return (
    <tr>
      <td>{serialNo}</td>
      <td>
        {name}
        <span className="sub-text">{description}</span>
      </td>
      <td>{moment(createdOn).format("MMM D, YYYY")}</td>
      <td>{moment(updatedOn).fromNow()}</td>
      <td>
        <Link className="btn btn-sm btn-outline-secondary" to={ProjectCanvasURL}>
          Open
        </Link>
      </td>
    </tr>
  );
}

function ProjectListTableBody({ projectList = [], projectListLoading }) {
  // Loader
  if (projectListLoading) {
    return <TableLoader colSpan={3} />;
  }

  // If there is no Project list
  if (projectList.length === 0) {
    return <TableDataNotFound message="No Project present" colSpan={3} />;
  }

  // Project  list table row
  return (
    <>
      {projectList.map((project, index) => {
        // project List Table Row
        return <ProjectListTableRow key={index} serialNo={index + 1} project={project} />;
      })}
    </>
  );
}

/**
 * Project List Table
 */
export default function ProjectListTable({ projectList, projectListLoading }) {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Project List Table Body */}
          <ProjectListTableBody projectList={projectList} projectListLoading={projectListLoading} />
        </tbody>
      </table>
    </div>
  );
}
