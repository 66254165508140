import React from "react";

// App

// Actions

// Components
import GraphConfig from "../../../reactflow/GraphConfig";

// Constants
const EdgeTypeNameMap = GraphConfig.EdgeTypeNameMap;
const EdgeTypes = Object.keys(EdgeTypeNameMap);

//
// Component
// ----------------------------------------------------------------------------

export default function SectionSelectionInfo({ graphId, selectedNodes = [], selectedEdges = [] }) {
  // Counts
  const selectedNodesCount = selectedNodes.length;
  const selectedEdgesCount = selectedEdges.length;

  //
  // Component Functions

  //
  return (
    <>
      <div className="sec-info p-3">
        <h6 className="px-1 mb-2">Selection</h6>
      </div>

      {selectedNodesCount > 0 && (
        <div className="sec-info p-3 px-4">
          <h6 className="mb-3">Nodes</h6>

          <ul className="list-unstyled list-graph-elems">
            {selectedNodes.map((n) => {
              const nId = n.id;
              const nData = n.data || {};

              return (
                <li className="" key={`sel-node-list-${nId}`}>
                  <span className="">
                    <i className="fas fa-angle-right"></i> {nData.name}
                  </span>
                  <small className="text-secondary">{nId}</small>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {selectedEdgesCount > 0 && (
        <div className="sec-info p-3 px-4">
          <h6 className="mb-3">Edges</h6>

          <ul className="list-unstyled list-graph-elems">
            {selectedEdges.map((e) => {
              const eId = e.id;
              const eData = e.data || {};

              return (
                <li className="" key={`sel-edge-list-${eId}`}>
                  <span className="">
                    <i className="fas fa-angle-right"></i> {eData.name}
                  </span>
                  <small className="text-secondary">{eId}</small>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
