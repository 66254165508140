import { combineReducers } from "redux";

// Error Reducer
import ErrorReducer from "../app/error/ErrorReducer";

// Auth Reducer
import { AuthReducer } from "./auth/AuthSlice";

// Graph Reducers
import { EdgeReducer } from "./graph/EdgeSlice";
import { EquationReducer } from "./graph/EquationSlice";
import { GraphReducer } from "./graph/GraphSlice";
import { NodeReducer } from "./graph/NodeSlice";

import { ParameterReducer } from "./graph/ParameterSlice";

// Project Reducer
import { ProjectReducer } from "./project/ProjectSlice";
import { ProjectListReducer } from "./project/ProjectListSlice";

// Opt Run Reducer
import { OptRunReducer } from "./project/OptRunSlice";
import { OptRunListReducer } from "./project/OptRunListSlice";

//
// Root Reducer definition

export const RootReducer = combineReducers({
  // Error
  error: ErrorReducer,

  // Auth Reducer
  auth: AuthReducer,

  // Graph Reducers
  edge: EdgeReducer,
  equation: EquationReducer,
  graph: GraphReducer,
  node: NodeReducer,
  parameter: ParameterReducer,

  // Project  Reducer
  project: ProjectReducer,
  projectList: ProjectListReducer,

  // Opt Run Reducers
  optRun: OptRunReducer,
  optRunList: OptRunListReducer,

  //
});
