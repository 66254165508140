// Utils
import URLs from "../../urls/URLs";

// URLs
import NodeApiURL from "../../urls/api-urls/graph/NodeApiURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

//
// API Functions
// ----------------------------------------------------------------------------

// Create Node
const createNode = async function (graphId, nodeInfo) {
  // API URL
  const apiURL = URLs.format(NodeApiURL.CreateNodeURL, { graphId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, nodeInfo);
};

//
// Update Node Info
const updateNodeInfo = async function (graphId, nodeUid, nodeInfo) {
  const { type = "", name = "", symbol = "", varType = "" } = nodeInfo || {};

  // Params
  const params = {
    type,
    name,
    symbol,
    varType,
  };

  // API URL
  const apiURL = URLs.format(NodeApiURL.UpdateNodeInfoByUidURL, { graphId, nodeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, params);
};

// Update Node Style
const updateNodeStyle = async function (graphId, nodeUid, styleObj) {
  // API URL
  const apiURL = URLs.format(NodeApiURL.UpdateNodeStyleByUidURL, { graphId, nodeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, {}, styleObj);
};

// Update Node Position
const updateNodePosition = async function (graphId, nodeUid, x, y) {
  // Params
  const params = {
    x,
    y,
  };

  // API URL
  const apiURL = URLs.format(NodeApiURL.UpdateNodePositionByUidURL, { graphId, nodeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, params);
};

// Update Node Equation Generation Status
const updateNodeEqnGenStatus = async function (graphId, nodeUid, generateEqns) {
  // Params
  const params = {
    generate: generateEqns,
  };

  // API URL
  const apiURL = URLs.format(NodeApiURL.UpdateNodeEqnGenStatusByUidURL, { graphId, nodeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, params);
};

//
// Delete Node
const deleteNode = async function (graphId, nodeUid) {
  // Params
  const params = {
    edges: true,
  };

  // API URL
  const apiURL = URLs.format(NodeApiURL.DeleteNodeByUidURL, { graphId, nodeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiURL, params);
};

//
// Export
// ----------------------------------------------------------------------------

const NodeAPI = {
  //
  createNode,

  //
  updateNodeInfo,
  updateNodeStyle,
  updateNodePosition,

  updateNodeEqnGenStatus,

  //
  deleteNode,
};

export default NodeAPI;
