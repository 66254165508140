import { useCallback, useState, memo, useEffect } from "react";
import { Handle, Position } from "reactflow";
import { getRightHandle, getLeftHandle } from "./ReactFlowUtil";
import InlineEdit from "../../../components/inline-edit/InlineEdit";

// CustomNode
// ----------------------------------------------------------------------------

function NodeHandles({ leftHandles, rightHandles }) {
  if (!leftHandles && !rightHandles) {
    return null;
  }
  const handles = leftHandles.concat(rightHandles);
  return handles.map((eachHandle) => {
    const { id, type, style, position } = eachHandle;
    return <Handle type={type} position={position} style={style} id={id} />
  });
}

function ConservationNode(node) {
  const { id, selected, data } = node;
  const { leftHandles, rightHandles } = data;
  const [leftHandleCnt, setLeftHandleCnt] = useState(leftHandles.length);
  const [rightHandleCnt, setRightHandleCnt] = useState(rightHandles.length);
  const [nodeLable, setNodeLabel] = useState("Update Name");

  function addRightHandle() {
    const handle = getRightHandle(rightHandleCnt);
    rightHandles.push(handle);
    setRightHandleCnt(rightHandles.length);
  }

  function addLeftHandle() {
    const handle = getLeftHandle(leftHandleCnt)
    leftHandles.push(handle);
    setLeftHandleCnt(leftHandles.length);
  }

  // useEffect(() => {


  // }, [inpHndCnt, outHndCnt]);

  return (
    <>
      {/* <Handle type="target" position={Position.Left} id="ta" /> */}
      <NodeHandles leftHandles={leftHandles} rightHandles={rightHandles} />
      <button onClick={addLeftHandle} style={{ width: 20, height: 20 }}>L</button >
      <button onClick={addRightHandle} style={{ width: 20, height: 20 }}>R</button >
      <InlineEdit value={nodeLable} setValue={setNodeLabel} />
      {/* <Handle type="target" position={Position.Left} id="tb" style={{ top: 5, backgroundColor: "pink" }} /> */}
      <div className={`${selected ? "selected" : ""}`}>
        <label htmlFor="text">{data.label}</label>
      </div>
      {/* <Handle type="source" position={Position.Right} id="sa" />
      <Handle type="source" position={Position.Right} id="sb" style={{ top: 15, backgroundColor: "pink" }} />
      <Handle type="source" position={Position.Right} id="sc" style={{ top: 25, backgroundColor: "red" }} /> */}
    </>
  );
}

export default memo(ConservationNode);
