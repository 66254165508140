// Utils
import URLs from "../../urls/URLs";

// URLs
import ParameterApiURL from "../../urls/api-urls/graph/ParameterApiURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

//
// API Functions
// ----------------------------------------------------------------------------

//
//
// Parameter

// Create Parameter
const createParameter = async function (graphId, parameterObj) {
  // API URL
  const apiURL = URLs.format(ParameterApiURL.CreateParameterURL, { graphId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, parameterObj);
};

// Update Parameter
const updateParameter = async function (graphId, parameterId, parameterObj) {
  // API URL
  const apiURL = URLs.format(ParameterApiURL.UpdateParameterURL, { graphId, parameterId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, {}, parameterObj);
};

// Delete Parameter
const deleteParameter = async function (graphId, parameterId) {
  // API URL
  const apiURL = URLs.format(ParameterApiURL.DeleteParameterURL, { graphId, parameterId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiURL);
};

//
// Export
// ----------------------------------------------------------------------------

const ParameterAPI = {
  //
  createParameter,

  //
  updateParameter,

  //
  deleteParameter,
};

export default ParameterAPI;
