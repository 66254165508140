import { createSlice } from "@reduxjs/toolkit";

// Intital state
const initialState = {
  // Get Project List
  projectList: [],
  projectListPagination: {},
  projectListLoading: false,
  projectListError: "",
};

// Project List Slice
const projectListSlice = createSlice({
  // Name
  name: "ProjectList",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    getProjectList: (state) => {
      state.projectListLoading = true;
    },

    getProjectListSuccess: (state, action) => {
      state.projectList = action.payload.projectList;
      state.projectListPagination = action.payload.projectListPagination;
      state.projectListError = "";
      state.projectListLoading = false;
    },

    getProjectListFailure: (state, action) => {
      state.projectListLoading = false;
      state.projectListError = action.payload.error;
    },
  },
});

// Reducer
export const ProjectListReducer = projectListSlice.reducer;

// Actions
export const ProjectListActions = projectListSlice.actions;
