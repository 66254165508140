// Utils
import URLs from "../../urls/URLs";

// URLs
import EquationApiURL from "../../urls/api-urls/graph/EquationApiURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

//
// API Functions
// ----------------------------------------------------------------------------

//
//
// Equation

// Create Equation
const createEquation = async function (graphId, equationObj) {
  // API URL
  const apiURL = URLs.format(EquationApiURL.CreateEquationURL, { graphId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, equationObj);
};

// Update Equation
const updateEquation = async function (graphId, equationId, equationObj) {
  // API URL
  const apiURL = URLs.format(EquationApiURL.UpdateEquationURL, { graphId, equationId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, {}, equationObj);
};

// Delete Equation
const deleteEquation = async function (graphId, equationId) {
  // API URL
  const apiURL = URLs.format(EquationApiURL.DeleteEquationURL, { graphId, equationId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiURL);
};

//
// Export
// ----------------------------------------------------------------------------

const EquationAPI = {
  // CRUD
  createEquation,
  updateEquation,
  deleteEquation,

  //
};

export default EquationAPI;
