//
import { Link } from "react-router-dom";

// Components

// Components :: ReactFlow
import GraphConfig from "../../reactflow/GraphConfig";

import URLs from "../../urls/URLs";
import PageURL from "../../urls/PageURL";

//
// Constants
const NodeTypeNameMap = GraphConfig.NodeTypeNameMap;

//
// Component
// ----------------------------------------------------------------------------

export default function ProjectSidebarLeft({ projectInfo, mode = "code", selection = "src" }) {
  // URLs
  const projectId = projectInfo.id;
  const projectSourcePageURL = URLs.format(PageURL.ProjectSourcePageURL, { projectId });
  const projectDataPageURL = URLs.format(PageURL.ProjectDataPageURL, { projectId });

  const projectOptListPageURL = URLs.format(PageURL.ProjectOptListPageURL, { projectId });

  const projectExecutePageURL = URLs.format(PageURL.ProjectExecutePageURL, { projectId });
  const projectResultPageURL = URLs.format(PageURL.ProjectResultPageURL, { projectId });

  //
  return (
    //
    <div className="sidebar sidebar-left">
      <div className="position-relative">
        {/** */}
        <div className="sec-info py-3">
          <h6 className="p-2 px-3 mb-0 text-uppercase fw-bold">Simulate</h6>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className={`nav-link ${selection === "opt" ? "active" : ""}`} to={projectOptListPageURL}>
                <i className="fa fa-chart-area m-1"></i> Optimize
              </Link>
            </li>
            <li className="nav-item d-none">
              <Link className={`nav-link ${selection === "opt-result" ? "active" : ""}`} to={projectResultPageURL}>
                <i className="fa fa-play m-1"></i> Results
              </Link>
            </li>
          </ul>
        </div>

        {/** */}
        <div className="sec-info py-3">
          <h6 className="p-2 px-3 mb-0 text-uppercase fw-bold">System</h6>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className={`nav-link ${selection === "src" ? "active" : ""}`} to={projectSourcePageURL}>
                <i className="far fa-square m-1"></i> Source
              </Link>
            </li>
            <li className="nav-item d-none">
              <Link className={`nav-link ${selection === "data" ? "active" : ""}`} to={projectDataPageURL}>
                <i className="fa fa-table m-1"></i> Data
              </Link>
            </li>
            <li className="nav-item d-none">
              <Link className={`nav-link ${selection === "script" ? "active" : ""}`} to={""}>
                <i className="fa fa-code m-1"></i> Scripts
              </Link>
            </li>
          </ul>
        </div>

        {/** */}
      </div>
    </div>
  );
}
