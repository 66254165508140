import { all, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

// Actions
import { ProjectActions } from "../../redux-slice/project/ProjectSlice";
import { setErrorInfo } from "../../app/error/ErrorActions";

// APIs
import ProjectAPI from "../../api/project/ProjectAPI";

// URLs
import URLs from "../../urls/URLs";
import PageURL from "../../urls/PageURL";

//
// Saga Functions
// ----------------------------------------------------------------------------

// Create Project
function* createProject(action) {
  const { projectData, navigate } = action.payload;

  try {
    const { id } = yield ProjectAPI.createProject(projectData);

    // Dispatching Action
    yield put(ProjectActions.createProjectSuccess());

    // Toaster
    toast.success("Project Created Successfully");

    // Navigate
    const projectCanvasPageURL = URLs.format(PageURL.ProjectCanvasPageURL, { projectId: id });
    navigate(projectCanvasPageURL);

    //
  } catch (error) {
    yield put(ProjectActions.createProjectFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update Project Information
function* updateProjectInfo(action) {
  const { projectId, projectData } = action.payload;
  try {
    yield ProjectAPI.updateProjectInfoById(projectId, projectData);

    // Dispatching Action
    yield put(ProjectActions.updateProjectSuccess());

    // Toaster
    toast.success("Project Updated Sucessfully");
  } catch (error) {
    yield put(ProjectActions.updateProjectFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Get Project
function* getProject(action) {
  const { projectId } = action.payload;

  try {
    const projectInfo = yield ProjectAPI.getProjectInfoById(projectId);

    // Dispatching Action
    yield put(ProjectActions.getProjectInfoSuccess({ projectInfo }));
  } catch (error) {
    yield put(ProjectActions.getProjectInfoFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Export
export default function* root() {
  yield all([
    takeLatest(ProjectActions.createProject.type, createProject),
    takeLatest(ProjectActions.updateProject.type, updateProjectInfo),
    takeLatest(ProjectActions.getProjectInfo.type, getProject),
  ]);
}
