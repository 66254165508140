import { Handle, Position } from "reactflow";

// Utils

// HandlesCont
// ----------------------------------------------------------------------------

function HandlesCont({ nodeId, type, handles = [], topOffset = 35, spacing = 15 }) {
  // Position
  const position = type === "source" ? Position.Right : Position.Left;

  return (
    <>
      {handles.map((handleId, idx) => {
        const handleUid = nodeId + "-" + handleId;
        const topPx = topOffset + idx * spacing;
        const styleObj = {
          top: topPx,
        };

        return <Handle key={handleUid} type={type} id={handleId} position={position} style={styleObj} />;
      })}
    </>
  );
}

export default HandlesCont;
