import { all, fork } from "redux-saga/effects";

// Auth Saga
import AuthSaga from "./auth/AuthSaga";

// Graph Sagas
import GraphSaga from "./graph/GraphSaga";
import NodeSaga from "./graph/NodeSaga";
import EdgeSaga from "./graph/EdgeSaga";
import ParameterSaga from "./graph/ParameterSaga";
import EquationSaga from "./graph/EquationSaga";

// OptRun Sagas
import OptRunSaga from "./project/OptRunSaga";
import OptRunListSaga from "./project/OptRunListSaga";

// Project Saga
import ProjectSaga from "./project/ProjectSaga";
import ProjetListSaga from "./project/ProjectListSaga";

//
// Root Saga

export default function* RootSaga() {
  yield all([
    // Auth Saga
    fork(AuthSaga),

    // Graph Sagas
    fork(GraphSaga),
    fork(NodeSaga),
    fork(EdgeSaga),
    fork(ParameterSaga),
    fork(EquationSaga),

    // Project  Saga
    fork(ProjectSaga),
    fork(ProjetListSaga),

    // OptRun Sagas
    fork(OptRunListSaga),
    fork(OptRunSaga),
  ]);
}
