// Constants

// URLs
const PageURL = {
  // Login Page
  LoginPageURL: "/a/login",
  ForgotPasswordPageURL: "/a/forgot-password",

  // Profile Page
  ProfilePageURL: "/profile",

  // Home Page
  DashboardPageURL: "/",

  // Project Pages

  ProjectListPageURL: "/p/list",

  ProjectCanvasPageURL: "/p/:projectId/canvas",
  ProjectSourcePageURL: "/p/:projectId/source",
  ProjectDataPageURL: "/p/:projectId/data",

  ProjectOptListPageURL: "/p/:projectId/opt",
  ProjectOptSetupPageURL: "/p/:projectId/opt/setup",
  ProjectOptProblemPageURL: "/p/:projectId/opt/problem",

  ProjectExecutePageURL: "/p/:projectId/run",

  ProjectResultPageURL: "/p/:projectId/result",

  // Error Page
  ErrorPageURL: "/error",

  //
};

// Export
export default PageURL;
