import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Constants & Utils
import { LocalStorageKeys } from "../../constants/GeneralConstants";
import { getItem } from "../LocalStorage";

// Components
import NavBranding from "./NavBranding";

// Images
import URL_IMG_LOGO from "../../assests/imgs/branding/logo.png";
import URL_IMG_LOGO_TEXT from "../../assests/imgs/branding/logo_alt.png";

// URLs
import PageURL from "../../urls/PageURL";

/**
 * Sidebar Menu
 */
const SidebarMenus = {
  PLATFORM: [
    {
      title: "Home",
      items: [
        { itemName: "Projects", itemIconCls: "fa fa-icon", itemLink: PageURL.ProjectListPageURL, childItems: [] },
      ],
    },
  ],
};

// Page Components

/**
 * {
 *   title: "Some text",
 *   items: []
 * }
 */
function Menu({ menu, isSidebarCollapsed, orgInfo }) {
  // Navigate
  const navigate = useNavigate();

  const { title, items = [] } = menu;

  return (
    <>
      {/* Menu Title */}
      {/* <li className="menu-title fs-5 text-black" key="t-menu">
        {isSidebarCollapsed ? title : "--"}
      </li> */}

      {/* Menu Items */}
      {items.map((menuItem, index) => {
        return <MenuItem key={index} menuItem={menuItem} orgInfo={orgInfo} isSidebarCollapsed={isSidebarCollapsed} />;
      })}
    </>
  );
}

/**
 * {
 *   itemName: "Some text",
 *   itemIconCls: "far fa-icon",
 *   itemLink: "/link",
 *   childItems: [
 *     {
 *       itemName: "",
 *       itemLink: ""
 *     }
 *   ]
 * }
 */
function MenuItem({ menuItem, orgInfo, isSidebarCollapsed }) {
  // State
  const [showSubMenu, setShowSubMenu] = useState(false);

  // Data
  const { itemName, itemIconCls, itemLink, childItems = [] } = menuItem;
  const { id: orgId, type: orgType } = orgInfo;

  // Item URL
  const menuItemURL = itemLink ? itemLink.replace(":orgId", orgId) : "#";

  // Check if it has sub-menu
  const hasSubMenu = childItems && childItems.length > 0;

  return (
    <li className={`menu-items fs-5 text-black ${showSubMenu ? "show-sub-menu" : ""}`}>
      <div className="menu-link" onClick={() => setShowSubMenu((prev) => !prev)}>
        <Link to={menuItemURL} className="menu-link">
          <i className={`menu-icons ${itemIconCls}`}></i>
          <span className={`link-name ${!isSidebarCollapsed && "d-none"}`}>{itemName}</span>
        </Link>
        {hasSubMenu && (
          <i className={`menu-icons arrow fa-solid fa-chevron-down fa-2xs ${!isSidebarCollapsed && "d-none"}`} />
        )}
      </div>
      <ul className={`sub-menu ${showSubMenu ? "d-block" : ""}`}>
        <li>
          <Link className={`link-name ${isSidebarCollapsed && "d-none"}`}>{itemName}</Link>
        </li>
        {childItems.map((childItem, index) => {
          const childItemName = childItem["itemName"];
          const childItemLink = childItem["itemLink"];
          const childItemURL = childItemLink ? childItemLink.replace(":orgId", orgId) : "#";

          return (
            <li key={index} className="mb-2">
              <Link to={childItemURL}>{childItemName}</Link>
            </li>
          );
        })}
      </ul>
    </li>
  );
}

/**
 * Sidebar Component
 */
export default function SideBar({ collapsible = false, showSideBar, setShowSideBar }) {
  // Authenticated Org Type
  const authOrgStr = getItem(LocalStorageKeys.AUTH_ORG) || "{}";
  const authOrg = JSON.parse(authOrgStr);
  const authOrgType = authOrg?.type;

  // Menus
  const sidebarMenuList = SidebarMenus[authOrgType];

  // Header Logo URL
  const URL_LOGO = showSideBar ? URL_IMG_LOGO_TEXT : URL_IMG_LOGO;

  return (
    <nav className={`sidebar ${showSideBar ? "" : "collapsed"}`}>
      {/* Side bar Header */}
      <NavBranding className="" logoURL={URL_LOGO} redirectURL={PageURL.DashboardPageURL} />

      <ul className={`nav-links ${showSideBar ? "" : "overflow-visible"}`}>
        {/* Itetrate over Menu group list */}
        {sidebarMenuList.map((menuGroup, idx) => {
          return <Menu key={idx} menu={menuGroup} isSidebarCollapsed={showSideBar} orgInfo={authOrg} />;
        })}
      </ul>
    </nav>
  );
}
