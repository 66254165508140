/**
 * ReactFlow Extension
 *
 * # Utils
 * # Custom Hooks
 *
 * References:
 * - https://github.com/wbkd/react-flow/issues/2147
 * -
 */
import { useReactFlow } from "reactflow";

//
// Private Functions
// ----------------------------------------------------------------------------

//
// Custom Hooks :: Node
// ----------------------------------------------------------------------------

const useNodeSelectStatus = (isSelected = false) => {
  // ReactFlow Hooks
  const { setNodes } = useReactFlow();

  // Update Node Data
  function updateNodeSelectedStatus(nodeId) {
    // invoke setNodes with updated Nodes
    setNodes((oldNodes) => {
      const updateNodes = oldNodes.map((node) => {
        if (node.id === nodeId) {
          node.selected = isSelected;
        }

        return node;
      });

      return updateNodes;
    });
  }

  return updateNodeSelectedStatus;
};

/**
 * Updates a Node data properties
 *
 * @param {*} nodeId
 * @returns
 */
const useUpdateNodeData = (nodeId) => {
  // ReactFlow Hooks
  const { setNodes } = useReactFlow();

  // Update Node Data
  function updateNodeData(dataUpdates) {
    // invoke setNodes with updated Nodes
    setNodes((oldNodes) => {
      const updateNodes = oldNodes.map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            ...dataUpdates,
          };
        }

        return node;
      });

      return updateNodes;
    });
  }

  return updateNodeData;
};

/**
 * Updates a Node Math data by property
 *
 * @param {*} nodeId
 * @returns
 */
const useUpdateNodeMathData = (nodeId) => {
  // ReactFlow Hooks
  const { setNodes } = useReactFlow();

  // Update Node Parameter
  function updateNodeMathData(propKey, propObj = {}, isDelete = false) {
    // invoke setNodes with updated Nodes
    setNodes((oldNodes) => {
      const updateNodes = oldNodes.map((node) => {
        if (node.id === nodeId) {
          // get existing values Array
          const valuesArr = node.data[propKey] || [];
          const filteredValArr = valuesArr.filter((exObj) => exObj.id !== propObj.id);

          // Check if its delete action
          if (!isDelete) {
            filteredValArr.push(propObj);
          }

          // Update Node data
          node.data = {
            ...node.data,
            [propKey]: filteredValArr,
          };
        }

        return node;
      });

      return updateNodes;
    });
  }

  return updateNodeMathData;
};

//
// Custom Hooks :: Edge
// ----------------------------------------------------------------------------

/**
 * Updates a Node data property
 *
 * @param {*} edgeId
 * @returns
 */
const useUpdateEdgeType = (edgeId) => {
  // ReactFlow Hooks
  const { setEdges } = useReactFlow();

  // Update Edge Data
  function updateEdgeType(newType) {
    // invoke setEdges with updated Edges
    setEdges((oldEdges) => {
      const updateEdges = oldEdges.map((edge) => {
        if (edge.id === edgeId) {
          edge.type = newType;
        }

        return edge;
      });

      return updateEdges;
    });
  }

  return updateEdgeType;
};

/**
 * Updates Edge data properties
 *
 * @param {*} edgeId
 * @returns
 */
const useUpdateEdgeData = (edgeId) => {
  // ReactFlow Hooks
  const { setEdges } = useReactFlow();

  // Update Edge Data
  function updateEdgeData(dataUpdates) {
    // invoke setEdges with updated Edges
    setEdges((oldEdges) => {
      const updateEdges = oldEdges.map((edge) => {
        if (edge.id === edgeId) {
          edge.data = {
            ...edge.data,
            ...dataUpdates,
          };
        }

        return edge;
      });

      return updateEdges;
    });
  }

  return updateEdgeData;
};

//
// Export
// ----------------------------------------------------------------------------

const ReactFlowExt = {
  //
  useNodeSelectStatus,
  useUpdateNodeData,
  useUpdateNodeMathData,

  //
  useUpdateEdgeType,
  useUpdateEdgeData,
};

export default ReactFlowExt;
