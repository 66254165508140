// Org Types
export const OrgTypes = {
  PLATFORM: {
    id: "PLATFORM",
    name: "",
    nameOfList: "",
  },
};

// Local Storage
export const LocalStorageKeys = {
  AUTH_USER: "AUTH_USER",
  AUTH_USER_PRIVILEGES: "AUTH_USER_PRIVILEGES",
  AUTH_ORG: "AUTH_ORG",
  AUTH_ORG_TYPE: "AUTH_ORG_TYPE",
};

export const DataPreLoadKeys = {
  //
};
