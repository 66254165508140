import { Mention, MentionsInput } from "react-mentions";

// Styles
import { style } from "./style";

// Reference Link : https://blog.logrocket.com/build-react-comment-form-mention-functionality/#scrollable-text-area

/**
 * Input With Mentions
 * @param {*} containerClassName
 * @param {*} mentionClassName
 * @param {*} value
 * @param {*} placeholder
 * @param {*} trigger : regex or words
 * @param {*} singleLine : Boolean Value
 * @param {*} onChange : Function
 * @param {*} suggestions : Array :[{id:"", display:""}]
 */
export default function InputWithMentions({
  containerClassName = "",
  mentionClassName = "",
  value = "",
  placeholder = "",
  trigger = "@",
  singleLine = false,
  onChange = () => {},
  suggestions = [],
  appendSpaceOnAdd = false,
}) {
  return (
    <MentionsInput
      singleLine={singleLine}
      style={style}
      value={value}
      onChange={onChange}
      className={containerClassName}
      placeholder={placeholder}
    >
      <Mention trigger={trigger} data={suggestions} className={mentionClassName} appendSpaceOnAdd={appendSpaceOnAdd} />
    </MentionsInput>
  );
}
