import React from "react";
import { getSimpleBezierPath } from "reactflow";

//
// Connection Line
// ----------------------------------------------------------------------------

const FILL_COLOR = "#ee00ff";
const STROKE_COLOR = "#ee00ff";
const STROKE_WIDTH = "#ee00ff";

// Style object for connection line (When connecting to nodes)
export const ConnectionLineStyle = {
  strokeWidth: STROKE_WIDTH,
  stroke: STROKE_COLOR,
};

export default function ConnectionLine(connection) {
  const { fromX, fromY, toX, toY, connectionLineStyle } = connection;
  const [edgePath] = getSimpleBezierPath({
    sourceX: fromX,
    sourceY: fromY,
    targetX: toX,
    targetY: toY,
  });

  return (
    <g>
      <path style={connectionLineStyle} fill="none" d={edgePath} />
      <circle cx={toX} cy={toY} fill={FILL_COLOR} r={STROKE_WIDTH} stroke={STROKE_COLOR} strokeWidth={1.5} />
    </g>
  );
}
