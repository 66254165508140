// Utils
import URLs from "../../urls/URLs";

// URLs
import ProjectApiURL from "../../urls/api-urls/project/ProjectApiURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

//
// API Functions
// ----------------------------------------------------------------------------

// Create Project
const createProject = async (projectData) => {
  // API URL
  const apiURL = URLs.format(ProjectApiURL.CreateProjectURL);

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, projectData);
};

// Update Project Info By Id
const updateProjectInfoById = async (projectId, projectData) => {
  // API URL
  const apiURL = URLs.format(ProjectApiURL.UpdateProjectInfoURL, { projectId: projectId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, {}, projectData);
};

// Get Project Info By Id
const getProjectInfoById = async (projectId) => {
  // API URL
  const apiURL = URLs.format(ProjectApiURL.GetProjectInfoURL, { projectId: projectId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL);
};

// Export
// ----------------------------------------------------------------------------

const ProjectAPI = {
  //
  getProjectInfoById,

  //
  createProject,

  //
  updateProjectInfoById,
};

export default ProjectAPI;
