import { memo, useState } from "react";
import { Position, useUpdateNodeInternals } from "reactflow";

import HandlesCont from "../connection/HandlesCont.jsx";
import GraphUtil from "../GraphUtil";

//
// Constants
const PX_TOP_OFFSET = 35;
const PX_SPACING = 30;

//
// SinkNode
// ----------------------------------------------------------------------------

function SinkNode({ id, type, selected, data = {} }) {
  // Node Internals
  const updateNodeInternals = useUpdateNodeInternals();

  // Handles
  const handleIds = data.handles?.target || [];

  // Parameters and Equations
  const parameters = data.parameters || [];
  const equations = data.equations || [];

  // Component State
  const [handlesCount, setHandlesCount] = useState(handleIds.length);
  const [bodyHeight, setBodyHeight] = useState(estimateBodyHeight());

  //
  // Functions

  function estimateBodyHeight() {
    // Update Body Height
    const bodyHeightUnits = handlesCount;
    const newBodyHeight = (bodyHeightUnits + 1) * PX_SPACING;

    return newBodyHeight;
  }

  function addHandle(isInput) {
    // Handles Array
    const handleCnt = handleIds.length;
    const lastHandleId = handleCnt === 0 ? "" : handleIds[handleCnt - 1];
    const nextHandleId = GraphUtil.nextHandleId(isInput, lastHandleId);

    handleIds.push(nextHandleId); // Push to Handles

    // Update Count to trigger re-render
    setHandlesCount(handlesCount + 1);

    // Body Height
    const newBodyHeight = estimateBodyHeight();
    setBodyHeight(newBodyHeight);

    // Update Internals
    updateNodeInternals(id);
  }

  //
  const nodeName = data?.name || "";

  //
  return (
    <>
      <div className="node-body" title={`"${nodeName} (Sink)"`} style={{ minHeight: bodyHeight }}>
        <div>
          <small className="mx-2 px-1">Sink</small>
          <div className="node-title">
            <small>{nodeName}</small>
          </div>
          <button className="btn btn-add-handle left" title="Add Output" onClick={() => addHandle(true)}>
            +
          </button>
        </div>

        {parameters && parameters.length > 0 && (
          <div className="sec-info">
            <p className="sec-title">Pameters</p>
            <ul className="list-unstyled mb-1">
              {parameters.map((p, idx) => {
                return (
                  <li key={`node-${id}-param-${idx}`}>
                    {p.name} ( <small>{p.symbol}</small> )
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="sec-info d-none">
          <p className="sec-title">Equations</p>
        </div>
      </div>

      {/** Target Handles */}
      <HandlesCont nodeId={id} type={"target"} handles={handleIds} topOffset={PX_TOP_OFFSET} spacing={PX_SPACING} />
    </>
  );
}

export default memo(SinkNode);
