import { createSlice } from "@reduxjs/toolkit";

// Intital state
const initialState = {
  // Get Project Information
  projectInfo: {},
  projectInfoLoading: false,
  projectInfoError: "",

  // Create Project
  createProjectLoading: false,
  CreateProjectError: "",

  // Update Project
  updateProjectInfoLoading: false,
  updateProjectInfoError: "",
};

// Project Slice
const projectSlice = createSlice({
  // Name
  name: "Project",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Get Project Information
    getProjectInfo: (state, action) => {
      state.projectInfoLoading = true;
    },

    getProjectInfoSuccess: (state, action) => {
      state.projectInfo = action.payload.projectInfo;
      state.projectInfoLoading = false;
      state.projectInfoError = "";
    },

    getProjectInfoFailure: (state, action) => {
      state.projectInfoLoading = false;
      state.projectInfoError = action.payload.error;
    },

    // Create Project
    createProject: (state) => {
      state.createProjectLoading = true;
    },

    createProjectSuccess: (state) => {
      state.createProjectLoading = false;
      state.CreateProjectError = "";
    },

    createProjectFailure: (state, action) => {
      state.createProjectLoading = false;
      state.CreateProjectError = action.payload.error;
    },

    // Update Project
    updateProject: (state) => {
      state.updateProjectInfoLoading = true;
    },

    updateProjectSuccess: (state) => {
      state.updateProjectInfoLoading = false;
      state.updateProjectInfoError = "";
    },

    updateProjectFailure: (state, action) => {
      state.updateProjectInfoLoading = false;
      state.updateProjectInfoError = action.payload.error;
    },
  },
});

// Reducer
export const ProjectReducer = projectSlice.reducer;

// Actions
export const ProjectActions = projectSlice.actions;
