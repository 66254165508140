// Component
// ----------------------------------------------------------------------------

function TitleSection({ body = "" }) {
  // if "body" is String then return :
  if (typeof body === "string") {
    return (
      <h2 title={body} className="title text-truncate">
        {body}
      </h2>
    );
  }

  // returning component itself
  return <div>{body}</div>;
}

/**
 * PageHeader Component
 */
export default function PageHeader({ title, actions, shadow = false, border = false, className = "" }) {
  // Props
  const shadowCls = shadow ? "shadow" : "";
  const borderCls = border ? "border" : "";

  return (
    <div className={`page-header ${shadowCls} ${borderCls} ${className}`}>
      {/** Title */}
      <TitleSection body={title} />

      {/** Actions */}
      {actions && <div className="page-actions">{actions}</div>}
    </div>
  );
}
