import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { GraphActions } from "../../../redux-slice/graph/GraphSlice";
import { ProjectActions } from "../../../redux-slice/project/ProjectSlice";

// Components
import CardCollapsible from "../../../components/card-collapsible/CardCollapsible";

// Components :: Graph / Reactflow
import GraphConfig from "../../../reactflow/GraphConfig";
import GraphUtil from "../../../reactflow/GraphUtil";

//
import ProjectTopbar from "../ProjectTopbar";
import ProjectSidebarLeft from "../ProjectSidebarLeft";

//
// Constants
const NodeTypeNameMap = GraphConfig.NodeTypeNameMap;
const VarTypeNameMap = GraphConfig.VarTypeNameMap;

//
// Page Components
// ----------------------------------------------------------------------------

/**
 * Page
 */
export default function ProjectExecutePage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const params = useParams();
  const { projectId } = params;
  const graphId = projectId; // graphId is just "projectId"

  // Dispatch API calls
  useEffect(() => {
    dispatch(ProjectActions.getProjectInfo({ projectId }));
    dispatch(GraphActions.getGraphData({ graphId }));
  }, [projectId, graphId, dispatch]);

  //
  // Selector State
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const graphData = useSelector((state) => state.graph.graphData);
  const graphDataLoading = useSelector((state) => state.graph.graphDataLoading);

  //
  // Graph Data
  const { nodes = [], edges = [] } = GraphUtil.toReactFlowFormat(graphId, graphData);

  //
  return (
    <>
      {/* Topbar */}
      <ProjectTopbar projectInfo={projectInfo} />

      {/* Sidebar Left */}
      <ProjectSidebarLeft projectInfo={projectInfo} selection={"opt"} />

      {/* Page Content */}
      <div className="main-cont position-right-0">
        <div className="content-wrapper">
          {/** */}
          <div className="container-fluid py-3 pb-5">{/**  */}</div>
        </div>
      </div>
    </>
  );
}
