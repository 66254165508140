import React from "react";

// Components
import SectionEdgeInfo from "./SectionEdgeInfo";
import SectionNodeInfo from "./SectionNodeInfo";
import SectionSelectionInfo from "./SectionSelectionInfo";
import SectionGraphInfo from "./SectionGraphInfo";

//
// Component
// ----------------------------------------------------------------------------

export default function CanvasSidebarRight({ graphId, nodes = [], edges = [], parametersMap = {}, equationsMap = {} }) {
  //
  // Graph State

  // NodesMap
  const nodesMap = React.useMemo(() => {
    const nMap = {};
    nodes.forEach((n) => {
      nMap[n.id] = n;
    });
    return nMap;
  }, [nodes]);

  const graphParameters = parametersMap[graphId] || [];
  const graphEquations = equationsMap[graphId] || [];

  //
  //
  // Graph State :: Selection

  const { selectedNodes, selectedEdges } = React.useMemo(() => {
    // Filter Selected
    const selectedNodes = nodes.filter((n) => n.selected) || [];
    const selectedEdges = edges.filter((e) => e.selected) || [];

    return { selectedNodes, selectedEdges };
  }, [nodes, edges]);

  const selectedNodesCount = selectedNodes.length;
  const selectedNode = selectedNodesCount === 1 && selectedNodes[0];

  const selectedEdgesCount = selectedEdges.length;
  const selectedEdge = selectedEdgesCount === 1 && selectedEdges[0];

  const totalSelectionCount = selectedNodesCount + selectedEdgesCount;

  //
  //
  // Graph State :: Selcted Node

  // Node :: Connected Edges
  const { selectedNodeInEdges, selectedNodeOutEdges } = React.useMemo(() => {
    // Sanity checks
    if (!selectedNode) {
      return {};
    }

    const selNodeId = selectedNode.id;

    // Connected Edges
    const selectedNodeInEdges = [];
    const selectedNodeOutEdges = [];
    edges?.forEach((e) => {
      // Input
      if (selNodeId === e.target) {
        selectedNodeInEdges.push(e);
      }

      // Outout
      if (selNodeId === e.source) {
        selectedNodeOutEdges.push(e);
      }
    });

    return { selectedNodeInEdges, selectedNodeOutEdges };
  }, [selectedNode, edges]);

  //
  //
  // Graph State :: Selcted Edge

  // Edge :: Connected Nodes
  const { selectedEdgeSourceNode, selectedEdgeTargetNode } = React.useMemo(() => {
    // Sanity checks
    if (!selectedEdge) {
      return {};
    }

    // Source
    const sourceNodeId = selectedEdge.source;
    const selectedEdgeSourceNode = sourceNodeId && nodesMap[sourceNodeId];

    // Target
    const targetNodeId = selectedEdge.target;
    const selectedEdgeTargetNode = targetNodeId && nodesMap[targetNodeId];

    return { selectedEdgeSourceNode, selectedEdgeTargetNode };
  }, [selectedEdge, nodesMap]);

  //
  // Graph State :: Variables
  const graphVariables = React.useMemo(() => {
    // Edges
    const edgeVars = edges.map(({ data }) => data);

    // Parameters
    const paramVars = Object.values(parametersMap).reduce((acc, pList) => {
      return [...acc, ...pList];
    }, []);

    // Variables
    const graphVariables = [...edgeVars, ...paramVars];
    return graphVariables;
  }, [edges, parametersMap]);

  //
  return (
    <>
      <div className="sidebar sidebar-right">
        <div className="position-relative">
          {/** */}

          {/** Graph Info */}
          {totalSelectionCount === 0 && ( //
            <SectionGraphInfo
              graphId={graphId}
              parameters={graphParameters}
              equations={graphEquations}
              variables={graphVariables}
            />
          )}

          {/** Selected Node Info */}
          {totalSelectionCount === 1 && selectedNodesCount === 1 && (
            <SectionNodeInfo
              graphId={graphId}
              node={selectedNode}
              inputEdges={selectedNodeInEdges}
              outputEdges={selectedNodeOutEdges}
            />
          )}

          {/** Selected Edge Info */}
          {totalSelectionCount === 1 && selectedEdgesCount === 1 && (
            <SectionEdgeInfo
              graphId={graphId}
              edge={selectedEdge}
              sourceNode={selectedEdgeSourceNode}
              targetNode={selectedEdgeTargetNode}
            />
          )}

          {/** Selected Elements Info*/}
          {totalSelectionCount > 1 && (
            <SectionSelectionInfo graphId={graphId} selectedNodes={selectedNodes} selectedEdges={selectedEdges} />
          )}

          {/** */}
        </div>
      </div>
    </>
  );
}
