import { memo } from "react";
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from "reactflow";

//
// AssociationEdge
// ----------------------------------------------------------------------------

function AssociationEdge(edge) {
  // Properties
  const { id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data = {} } = edge;

  // Path
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer></EdgeLabelRenderer>
    </>
  );
}

export default memo(AssociationEdge);
