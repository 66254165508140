import { createSlice } from "@reduxjs/toolkit";

// Intital state
const initialState = {
  // Create Opt Run
  createOptRunLoading: false,
  createOptRunError: "",

  // Update Opt Run
  updateOptRunLoading: false,
  updateOptRunError: "",

  // Get Opt Run
  optRun: {},
  optRunLoading: false,
  optRunError: "",

  // Get Opt Run
  optRunProblem: "",
  optRunProblemLoading: false,
  optRunProblemError: "",
};

// OptRun Slice
const optRunSlice = createSlice({
  // Name
  name: "OptRun",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Create Opt Run
    createOptRun: (state) => {
      state.createOptRunLoading = true;
    },

    createOptRunSuccess: (state) => {
      state.createOptRunLoading = false;
      state.createOptRunError = "";
    },

    createOptRunFailure: (state, action) => {
      state.createOptRunLoading = false;
      state.createOptRunError = action.payload.error;
    },

    // Update Opt Run
    updateOptRun: (state) => {
      state.updateOptRunLoading = true;
    },

    updateOptRunSuccess: (state) => {
      state.updateOptRunLoading = false;
      state.updateOptRunError = "";
    },

    updateOptRunFailure: (state, action) => {
      state.updateOptRunLoading = false;
      state.updateOptRunError = action.payload.error;
    },

    // Get Opt Run
    getOptRun: (state) => {
      state.optRunLoading = true;
    },

    getOptRunSuccess: (state, action) => {
      state.optRun = action.payload.optRun;
      state.optRunLoading = false;
      state.optRunError = "";
    },

    getOptRunFailure: (state, action) => {
      state.optRunLoading = false;
      state.optRunError = action.payload.error;
    },

    // Get Opt Run
    getOptRunProblem: (state) => {
      state.optRunProblemLoading = true;
    },

    getOptRunProblemSuccess: (state, action) => {
      state.optRunProblem = action.payload.optRunProblem;
      state.optRunProblemLoading = false;
      state.optRunProblemError = "";
    },

    getOptRunProblemFailure: (state, action) => {
      state.optRunProblemLoading = false;
      state.optRunProblemError = action.payload.error;
    },
  },
});

// Reducer
export const OptRunReducer = optRunSlice.reducer;

// Actions
export const OptRunActions = optRunSlice.actions;
