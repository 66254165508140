import { useEffect, useState } from "react";

// Hooks
import { useUpdateQueryParams } from "../../hooks/UpdateQueryParams";

// Constants
import { DefaultPagination, QueryParamsKeys } from "../../constants/WebConstants";

// Components
import { Button } from "../button/Button";
import ErrorMessage from "../../components/error-message/ErrorMessage";
import Loader from "../loader/Loader";

// Page Component
function PasswordIcon({ isInputTypePassword, showPassword, setShowPassword }) {
  // If input type is password
  if (isInputTypePassword) {
    return (
      <span className="input-group-text bg-body-secondary" onClick={() => setShowPassword((prev) => !prev)}>
        <i className={`fa-regular ${showPassword ? "fa-eye" : "fa-eye-slash"}`}></i>
      </span>
    );
  }

  return "";
}

function SearchButton({ searchText, setSearchText, updateQueryParams, isSearchButton }) {
  // Search
  function handleSubmit() {
    const params = {
      search: searchText,
      pn: DefaultPagination.pageNumber,
      ps: DefaultPagination.pageSize,
    };

    // Updating Url
    updateQueryParams({ params });
  }

  // Reset Input
  function resetSearch() {
    // Setting search text to empty string
    setSearchText("");

    // Here search is given empty instead of giving searchText, because state is not updated immediately
    const params = {
      search: "",
      pn: DefaultPagination.pageNumber,
      ps: DefaultPagination.pageSize,
    };

    // Updating Url
    updateQueryParams({ params });
  }

  // If search button value is false
  if (!isSearchButton) {
    return null;
  }

  return (
    <>
      {/* Search Button */}
      <Button label="Search" className="ms-2" color="success" onClick={handleSubmit} />

      {/* Reset Button */}
      <Button label="Reset" className="ms-2" color="dark" onClick={resetSearch} />
    </>
  );
}

/**
 * Input Component
 * @param {*} type
 * @param {*} id
 * @param {*} value : Input Value
 * @param {*} name
 * @param {*} className
 * @param {*} onChange : Function
 * @param {*} placeholder
 * @param {*} disabled : Boolean Value
 * @param {*} error : Formik error
 * @param {*} loading : Boolean Value
 * @param {*} spinnerClassName : Class for spinner
 * @param {*} isSearchButton : Boolean Value
 */
export default function Input({
  type = "text",
  id,
  value,
  name,
  className = "",
  containerClassName = "",
  onChange = () => {},
  placeholder = "Enter text..",
  disabled = false,
  error = "",
  loading = false,
  spinnerClassName = "",
  isSearchButton = false,
  inputRef = null,
  ...rest
}) {
  // Query Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Getting Search Text From Url
  const search = searchParams.get(QueryParamsKeys.searchText);

  // State
  const [searchText, setSearchText] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // useEffect
  useEffect(() => {
    setSearchText(search || "");
  }, [search]);

  // Setting Value For Input
  const inputValue = isSearchButton ? searchText : value;

  // Setting onChange Function
  const inputOnChange = isSearchButton
    ? ({ target }) => {
        setSearchText(target.value);
      }
    : onChange;

  // Showing Password Icon, if input type is password
  const isInputTypePassword = type === "password";

  // Container Class
  const inputContainerClassName = isSearchButton
    ? `d-flex justify-content-between col-4 ${containerClassName}`
    : containerClassName;

  return (
    <div className={inputContainerClassName}>
      <div className="input-group">
        <input
          ref={inputRef}
          type={!showPassword || !isInputTypePassword ? type : "text"}
          id={id}
          className={`form-control shadow-none ${className} ${error && "border-danger"}`}
          placeholder={placeholder}
          value={inputValue}
          name={name}
          onChange={inputOnChange}
          disabled={disabled}
          {...rest}
        />

        {loading && <Loader containerClassName="input-group-text" spinnerClassName={spinnerClassName} />}

        {/*Password Icon */}
        <PasswordIcon
          isInputTypePassword={isInputTypePassword}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
      </div>

      {/* Search Button */}
      <SearchButton
        isSearchButton={isSearchButton}
        searchText={searchText}
        setSearchText={setSearchText}
        updateQueryParams={updateQueryParams}
      />

      {/* To show error message */}
      {error && <ErrorMessage error={error} />}
    </div>
  );
}
