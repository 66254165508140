import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Constants
import PageURL from "./urls/PageURL";

// CSS and JS
import "./App.scss";
import "bootstrap/dist/js/bootstrap.js";
import "react-toastify/dist/ReactToastify.css";

// App Setup
import ErrorHandler from "./app/error/ErrorHandler";
import ProtectedRoute from "./app/ProtectedRoute";
import Layout from "./app/layout/Layout";
import LayoutCanvas from "./app/layout/LayoutCanvas";

// Pages :: Error
import ErrorPage from "./pages/error/ErrorPage";

// Pages :: Auth
import LoginPage from "./pages/auth/LoginPage";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";

// Pages :: Profile
import ProfilePage from "./pages/user/user-profile/ProfilePage";

// Pages :: Dashboard
import DashboardPage from "./pages/dashboard/DashboardPage";

// Pages :: Projects
import ProjectListPage from "./pages/user/user-projects/ProjectListPage";

import ProjectCanvasPage from "./pages/project/project-canvas/ProjectCanvasPage";
import ProjectSourcePage from "./pages/project/project-source/ProjectSourcePage";
import ProjectDataPage from "./pages/project/project-data/ProjectDataPage";

import ProjectOptListPage from "./pages/project/project-opt-list/ProjectOptListPage";
import ProjectOptSetupPage from "./pages/project/project-opt-setup/ProjectOptSetupPage";
import ProjectOptProblemPage from "./pages/project/project-opt-problem/ProjectOptProblemPage";

import ProjectExecutePage from "./pages/project/project-execute/ProjectExecutePage";
import ProjectResultPage from "./pages/project/project-result/ProjectResultPage";

// Pages :: Dev
import devRoutes from "./dev/DevRoutes";

//
// Router
// ----------------------------------------------------------------------------

const router = createBrowserRouter([
  // Error Pages
  {
    path: PageURL.ErrorPageURL,
    element: <ErrorPage />,
  },

  // Layout :: Topbar
  {
    element: <Layout topbar={false} sidebar={false} />,
    children: [
      // Auth Pages
      {
        path: PageURL.LoginPageURL,
        element: <LoginPage />,
      },
      {
        path: PageURL.ForgotPasswordPageURL,
        element: <ForgotPasswordPage />,
      },
    ],
  },

  // Layout :: Sidebar
  {
    path: "/",
    element: (
      <ErrorHandler>
        <ProtectedRoute>
          <Layout topbar={true} sidebar={true} sidebarCollapsible={false} />
        </ProtectedRoute>
      </ErrorHandler>
    ),
    children: [
      {
        path: PageURL.DashboardPageURL,
        element: <DashboardPage />,
      },

      {
        path: PageURL.ProjectListPageURL,
        element: <ProjectListPage />,
      },

      {
        path: PageURL.ProfilePageURL,
        element: <ProfilePage />,
      },
    ],
  },

  // Layout :: Shell
  {
    element: (
      <ProtectedRoute>
        <LayoutCanvas />
      </ProtectedRoute>
    ),
    children: [
      {
        path: PageURL.ProjectCanvasPageURL,
        element: <ProjectCanvasPage />,
      },
      {
        path: PageURL.ProjectSourcePageURL,
        element: <ProjectSourcePage />,
      },
      {
        path: PageURL.ProjectDataPageURL,
        element: <ProjectDataPage />,
      },

      {
        path: PageURL.ProjectOptListPageURL,
        element: <ProjectOptListPage />,
      },
      {
        path: PageURL.ProjectOptSetupPageURL,
        element: <ProjectOptSetupPage />,
      },
      {
        path: PageURL.ProjectOptProblemPageURL,
        element: <ProjectOptProblemPage />,
      },

      {
        path: PageURL.ProjectExecutePageURL,
        element: <ProjectExecutePage />,
      },
      {
        path: PageURL.ProjectResultPageURL,
        element: <ProjectResultPage />,
      },
    ],
  },

  // Dev Pages
  ...devRoutes,
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
