import { createSlice } from "@reduxjs/toolkit";

// Intital state
const initialState = {
  // Get Opt Run List
  optRunList: [],
  optRunListPagination: {},
  optRunListLoading: false,
  optRunListError: "",
};

// OptRun List Slice
const optRunListSlice = createSlice({
  // Name
  name: "OptRunList",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Get Opt Run List
    getOptRunList: (state) => {
      state.optRunListLoading = true;
    },

    getOptRunListSuccess: (state, action) => {
      state.optRunList = action.payload.optRunList;
      state.optRunListPagination = action.payload.optRunListPagination;
      state.optRunListError = "";
      state.optRunListLoading = false;
    },

    getOptRunListFailure: (state, action) => {
      state.optRunListLoading = false;
      state.optRunListError = action.payload.error;
    },
  },
});

// Reducer
export const OptRunListReducer = optRunListSlice.reducer;

// Actions
export const OptRunListActions = optRunListSlice.actions;
