/**
 * Odd-ball URL Utilities
 */

//
// ----------------------------------------------------------------------------

/**
 * Format the Given URL with path/request parameters substitued with the passed values
 */
const URLs = {
  //
  format: function (inUrlStr = "", argVals = {}) {
    // Iterate over args and substitute values
    let pageURL = inUrlStr;
    for (const [key, value] of Object.entries(argVals)) {
      const pathParam = ":" + key;
      pageURL = pageURL.replace(pathParam, value);
    }

    return pageURL;
  },
};

// Export
export default URLs;
