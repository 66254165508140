import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

// Components
import PageHeader from "../../../app/layout/PageHeader";

// Sections

// Page Components
// ----------------------------------------------------------------------------

function PageHeaderSection({}) {
  // Content
  const pageTitle = "Sample Page Header ";
  const pageActions = <div className="btn-cont"></div>;

  return <PageHeader title={pageTitle} actions={pageActions} />;
}

/**
 * Page
 */
export default function LayoutPage() {
  // Page Params
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  return (
    <>
      <PageHeaderSection />

      {/* Page Content */}
      <div className="page-content">
        <div className="row">
          <div className="col-8">Sample Page Body</div>
          <div className="col-4"></div>
        </div>
      </div>
    </>
  );
}
