// Constants
import { ContentType } from "../../app/http/HttpConstants";

// Urls
import { loginUrl, resetPasswordUrl } from "../../urls/api-urls/auth/AuthURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Login
async function loginApi(username, password) {
  const bodyFormData = new FormData();
  bodyFormData.set("username", username);
  bodyFormData.set("password", password);

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(loginUrl, {}, bodyFormData, ContentType.APP_FORM_URL_ENCODED);
}

// Reset Password
async function resetPasswordApi(email = "", newPassword = "") {
  const params = {
    userEmail: email,
  };

  const bodyFormData = new FormData();
  bodyFormData.set("newPassword", newPassword);

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(resetPasswordUrl, params, bodyFormData, ContentType.APP_FORM_URL_ENCODED);
}

const AuthAPI = {
  loginApi,
  resetPasswordApi,
};

export default AuthAPI;
