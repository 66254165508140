import { useEffect, useState } from "react";

// Components
import Modal from "../../../../components/modal/Modal";

//
import GraphConfig from "../../../../reactflow/GraphConfig";
import GraphUtil from "../../../../reactflow/GraphUtil";

//
// Constants
const VarType = GraphConfig.VarType;
const VarTypeNameMap = GraphConfig.VarTypeNameMap;

//
// Component
// ----------------------------------------------------------------------------

//
// TODO Modal State to be reset after "onFormSubmit" Trigger
//
export default function GraphParameterModal({ modalId, graphId, nodeUid, parameter = {}, varPrefix, onSave }) {
  //

  // Component State
  const [varType, setVarType] = useState(parameter.varType || "OTHER");
  const [name, setName] = useState(parameter.name || "");
  const [symbol, setSymbol] = useState(parameter.symbol || "");

  // Update Symbol on Name and Type change
  useEffect(() => {
    const genSymbol = GraphUtil.toParamVarSymbol(varPrefix, varType, name);
    setSymbol(genSymbol);
  }, [varPrefix, varType, name]);

  //
  // Component Functions

  //
  const onFormSubmit = (e) => {
    e.preventDefault(); // Prevent Default Submit

    // Form Data
    const formData = {
      varType,
      name,
      symbol,
    };

    // Trigger onSave callback
    onSave(formData);
  };

  //
  return (
    <Modal id={modalId} title={`${nodeUid ? "Node" : "Graph"} Parameter`}>
      {/* Project Create Modal */}
      <form className="form-horizontal pb-3" onSubmit={onFormSubmit}>
        <div className="form-group mb-2">
          <label className="form-label">
            Variable Type <span className="text-danger">*</span>
          </label>
          <select
            className="form-select form-select-sm border-0 px-1 mb-2"
            name="varType"
            value={varType || ""}
            onChange={(e) => setVarType(e.target.value)}
          >
            <option value={""}>--</option>
            {Object.keys(VarType).map((vt) => {
              const varTypeName = VarTypeNameMap[vt];
              return (
                <option key={vt} value={vt}>
                  {varTypeName}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group mb-2">
          <label className="form-label">
            Name <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            type="text"
            name={"name"}
            value={name || ""}
            required
            autoComplete="off"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group mb-2">
          <label className="form-label">
            Variable Symbol <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            type="text"
            name={"symbol"}
            value={symbol || ""}
            required
            onChange={(e) => setSymbol(e.target.value)}
          />
        </div>

        <div className="btn-cont mt-4 text-end">
          <button className="btn btn-sm btn-primary" type="submit" data-bs-dismiss="modal" aria-label="Close">
            Save Parameter
          </button>
        </div>
      </form>
    </Modal>
  );
}
