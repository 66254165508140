// Utils
import URLs from "../../urls/URLs";

// URLs
import OptRunApiURL from "../../urls/api-urls/project/OptRunApiURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

//
// API Functions
// ----------------------------------------------------------------------------

// Get Opt Run History
const getOptRunList = async function (projectId) {
  // API URL
  const apiURL = URLs.format(OptRunApiURL.GetOptRunListURL, { projectId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL);
};

//
// Export
// ----------------------------------------------------------------------------

const OptRunListAPI = {
  //
  getOptRunList,
};

export default OptRunListAPI;
