import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Actions
import { ParameterActions } from "../../../../redux-slice/graph/ParameterSlice";

// Components
import CardCollapsible from "../../../../components/card-collapsible/CardCollapsible";
import ReactFlowExt from "../../../../reactflow/ReactFlowExt";
import GraphParameterModal from "../modal/GraphParameterModal";

// Constants
const ID_MODAL_NODE_PARAM = "modal-node-parameter";

//
// Component
// ----------------------------------------------------------------------------

export default function GraphParametersCard({ collapsed, graphId, nodeUid, parameters = [], parameterVarPrefix }) {
  // dispatch
  const dispatch = useDispatch();

  // Component State
  const [selectedParameter, setSelectedParameter] = useState({});

  //
  // Node Info
  const updateNodeMathData = ReactFlowExt.useUpdateNodeMathData(nodeUid);

  //
  //
  // Component Functions

  // Parameter Delete
  const deleteNodeParameter = (parameterObj) => {
    // Dispatch Action
    dispatch(ParameterActions.deleteParameter({ parameterObj, updateNodeMathData }));
    // update state
    setSelectedParameter({});
  };

  // Form onSubmit
  function onFormSubmit({ varType, name, symbol }) {
    // Parameter Object
    const parameterObj = {
      ...selectedParameter,
      graphId,
      nodeUid,
      varType,
      name,
      symbol,
    };

    const parameterId = selectedParameter?.id;

    // Dispatch Action
    const isUpdate = parameterId !== undefined;
    if (isUpdate) {
      dispatch(ParameterActions.updateParameter({ parameterId, parameterObj, updateNodeMathData }));
    } else {
      dispatch(ParameterActions.createParameter({ parameterObj, updateNodeMathData }));
    }

    // update state
    setSelectedParameter({});
  }

  //
  return (
    <>
      <CardCollapsible header={"Parameters"} collapsed={collapsed} className="pb-1" id="card-sel-node-params">
        <>
          <ul className="list-unstyled list-graph-elems">
            {parameters.map((p, idx) => {
              const { id = "", name = "", symbol = "", isGenerated = false } = p || {};

              //
              return (
                <li className="" key={`param-${id}`}>
                  <span className="">
                    {name} ( {symbol} )
                  </span>

                  {!isGenerated && (
                    <span>
                      <span
                        className="text-warning cursor-pointer ms-1"
                        onClick={() => setSelectedParameter(p)}
                        data-bs-toggle="modal"
                        data-bs-target={`#${ID_MODAL_NODE_PARAM}`}
                        title="Click to edit"
                      >
                        <i className="fas fa-edit"></i>
                      </span>
                      <span
                        className="text-danger cursor-pointer ms-1"
                        onClick={() => deleteNodeParameter(p)}
                        title="Click to delete"
                      >
                        <i className="fas fa-times-circle"></i>
                      </span>
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
          <div>
            <button
              className="btn btn-xs btn-outline-secondary"
              onClick={() => setSelectedParameter({})}
              data-bs-toggle="modal"
              data-bs-target={`#${ID_MODAL_NODE_PARAM}`}
            >
              Add Parameter
            </button>
          </div>
        </>
      </CardCollapsible>

      {/** Parameter Modal */}
      <GraphParameterModal
        modalId={ID_MODAL_NODE_PARAM}
        graphId={graphId}
        nodeUid={nodeUid}
        parameter={selectedParameter}
        varPrefix={parameterVarPrefix}
        onSave={onFormSubmit}
      />

      {/** */}
    </>
  );
}
