import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import TopNav from "./TopNav";
import Sidebar from "./Sidebar";

/**
 * Layout Component
 *
 * TODO :: Handle the case where we need Sidebar but no Topbar
 */
export default function Layout({
  themeId, //
  topbar = false,
  sidebar = false,
  sidebarCollapsible = false,
}) {
  // Page State
  const [showSideBar, setShowSideBar] = useState(true);

  // Data
  const layoutTopbarCls = topbar && !sidebar ? "layout-topbar" : "";
  const layoutSidebarCls = sidebar ? "layout-sidebar" : "";

  const isSidebarCollapsed = sidebar && !showSideBar;

  return (
    <div
      className={`page ${layoutTopbarCls} ${layoutSidebarCls}`}
      data-theme={themeId}
    >
      {/* Topbar */}
      {(topbar || sidebar) && ( //
        <TopNav
          sidebarCollapsible={sidebarCollapsible}
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
      )}

      {/* Sidebar */}
      {sidebar && ( //
        <Sidebar
          collapsible={sidebarCollapsible}
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
      )}

      {/* Main Content */}
      <div className={`main-cont ${isSidebarCollapsed ? "collapsed" : ""}`}>
        <div className={"content-wrapper "}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
