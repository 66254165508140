import { useEffect, useState } from "react";

// CSS
import "./InlineEdit.scss";

//
// Component
// ----------------------------------------------------------------------------

function InlineEdit({ value, setValue, className = "", title = "Click to edit" }) {
  // Local state to Capture value changes
  const [newValue, setNewValue] = useState(value || "");

  // Events :: On KeyDown
  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur();
    }
  };

  // Events :: On Blur
  const onBlur = (event) => {
    const onBlurVal = event.target.value;
    if (!onBlurVal || onBlurVal.trim() === "") {
      // Don't allow empty String
      setNewValue(value);
    } else {
      // invoke setValue funtion
      setValue(onBlurVal);
    }
  };

  // Reset the Value when the input Value changes
  useEffect(() => {
    setNewValue(value);
  }, [value]);

  //
  return (
    <input
      className={`inp-inline-edit ${className}`}
      type="text"
      value={newValue}
      onChange={(e) => setNewValue(e.target.value)}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      title={title}
    />
  );
}

export default InlineEdit;
