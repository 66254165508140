import { Outlet } from "react-router-dom";

/**
 * Layout Component for Canvas
 */
export default function LayoutCanvas({ themeId }) {
  return (
    <div className={"page layout-canvas"} data-theme={themeId}>
      <Outlet />
    </div>
  );
}
