import { createSlice } from "@reduxjs/toolkit";

// Intital state
const initialState = {
  // Get Graph Data
  graphData: {},
  graphDataLoading: false,
  graphDataError: "",

  // Refresh Graph
  refreshGraphLoading: false,
  refreshGraphError: "",

  // Graph Validation state
  graphValidationLoading: false,
  graphValidationSuccess: true,
  graphWarnings: {},
  graphErrors: {},
};

// Graph Slice
const graphSlice = createSlice({
  // Name
  name: "Graph",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Get Graph Data
    getGraphData: (state) => {
      state.graphDataLoading = true;
    },

    getGraphDataSuccess: (state, action) => {
      state.graphData = action.payload.graphData;
      state.graphDataLoading = false;
      state.graphDataError = "";
    },

    getGraphDataFailure: (state, action) => {
      state.graphDataLoading = false;
      state.graphDataError = action.payload.error;
    },

    // Refresh Graph setup
    refreshGraph: (state) => {
      state.refreshGraphLoading = true;
    },

    refreshGraphSuccess: (state, action) => {
      state.refreshGraphLoading = false;
      state.refreshGraphError = "";
    },

    refreshGraphFailure: (state, action) => {
      state.refreshGraphLoading = false;
      state.refreshGraphError = action.payload.error;
    },

    // Graph Validation
    validateGraph: (state) => {
      state.graphValidationLoading = true;
    },

    validateGraphCompleted: (state, action) => {
      state.graphWarnings = action.payload.warnings;
      state.graphErrors = action.payload.errors;
      state.graphValidationSuccess = action.payload.validationSuccess;

      state.graphValidationLoading = false;
    },
  },
});

// Reducer
export const GraphReducer = graphSlice.reducer;

// Actions
export const GraphActions = graphSlice.actions;
