export const style = {
  control: {
    minHeight: 50,
  },
  input: {
    padding: 9,
  },
  suggestions: {
    marginTop: "30px",
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};
