// Utils
import URLs from "../../urls/URLs";

// URLs
import GraphApiURL from "../../urls/api-urls/graph/GraphApiURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

//
// API Functions
// ----------------------------------------------------------------------------

// Get Graph Data By Id
const getGraphDataById = async function (graphId) {
  // API URL
  const apiURL = URLs.format(GraphApiURL.GetDataByIdURL, { graphId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL);
};

//
// Refresh Generated Data
const refreshGraph = async function (graphId) {
  // API URL
  const apiURL = URLs.format(GraphApiURL.RefreshGraphSetupURL, { graphId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL);
};

//
// Export
// ----------------------------------------------------------------------------

const GraphAPI = {
  getGraphDataById,

  //
  refreshGraph,

  //
};

export default GraphAPI;
