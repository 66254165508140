// Pagination
export const DefaultPagination = {
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0,
};

export const QueryParamsKeys = {
  // Pagination
  pageNumber: "pn",
  pageSize: "ps",

  //
  orgType: "orgType",
  type: "type",
  searchText: "search",

  //
  optRunId: "optRunId",

  // TODO : move to page state
  showEdge: "showEdge",
};
