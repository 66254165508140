// Graph Node API URLs

//
const NodeApiURL = {
  //
  CreateNodeURL: "/api/v1/graph/:graphId/node",

  //
  UpdateNodeInfoByUidURL: "/api/v1/graph/:graphId/node/:nodeUid",
  UpdateNodeStyleByUidURL: "/api/v1/graph/:graphId/node/:nodeUid/style",
  UpdateNodePositionByUidURL: "/api/v1/graph/:graphId/node/:nodeUid/position",

  UpdateNodeEqnGenStatusByUidURL: "/api/v1/graph/:graphId/node/:nodeUid/eqns",

  //
  DeleteNodeByUidURL: "/api/v1/graph/:graphId/node/:nodeUid",

  //
};

// Export
export default NodeApiURL;
