import { Position } from "reactflow";

export const getNodeId = () => `Node${+new Date()}`;

export function createNode(label, position, type) {

  const newNode = {
    id: getNodeId(),
    data: {},
  }


  // Set Node Position
  if (!position) {
    const node_position = {
      x: Math.random() * window.innerWidth - 100,
      y: Math.random() * window.innerHeight,
    };
    newNode['position'] = node_position;
  } else {
    newNode['position'] = position;
  }


  // Set Node Label
  if (label) {
    newNode.data["label"] = label;
  }

  // Set Node type (if not specified by default it is "default" type)
  if (type) {
    newNode["type"] = type;
  }

  // Add one Left and Right Handles
  newNode.data["leftHandles"] = [getLeftHandle(0)];
  newNode.data["rightHandles"] = [getRightHandle(0)];

  return newNode;
}

export function addInputNode(position, setNodes) {
  const newNode = createNode("input", position, "conservation");
  setNodes((nds) => nds.concat(newNode));
}

export function getLeftHandle(currentCount, nodeHeight = 100) {
  const top_position = currentCount * 10;
  return { type: "source", style: { top: top_position }, position: Position.Left, id: `s${currentCount}` };
}


export function getRightHandle(currentCount, nodeHeight = 100) {
  const top_position = currentCount * 10;
  return { type: "target", style: { top: top_position }, position: Position.Right, id: `t${currentCount}` };
}

export function actionOnNodeDragComplete(mouseEvent, node) {
  console.log("On Drag Complete NODE info:", node);
  console.log("On Drag Complete MouseEvent info:", mouseEvent);
}

export const connectionLineStyle = {
  strokeWidth: 3,
  stroke: 'black',
};