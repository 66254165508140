import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

// Constants
import { QueryParamsKeys } from "../../../constants/WebConstants";

// Components
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TableHeaders from "../../../components/table/TableHeaders";
import TableLoader from "../../../components/table/TableLoader";

// App Utilities
import URLs from "../../../urls/URLs";
import PageURL from "../../../urls/PageURL";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "S.No.",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  // TODO : uncomment when needed
  // {
  //   title: {
  //     displayName: "Objective",
  //   },
  // },
  {
    title: {
      displayName: "Run at",
    },
  },
  {
    title: {
      displayName: "",
    },
  },
];

// Page Components
function OptRunListTableRow({ serialNo, projectId, optRun }) {
  // Data
  const { id, name, objectives, notes, timestamp } = optRun;

  const OptRunSetupPageURLbase = URLs.format(PageURL.ProjectOptSetupPageURL, { projectId });
  const OptRunSetupPageURL = OptRunSetupPageURLbase + `?${QueryParamsKeys.optRunId}=` + id;

  const OptRunProblemPageURLbase = URLs.format(PageURL.ProjectOptProblemPageURL, { projectId });
  const OptRunProblemPageURL = OptRunProblemPageURLbase + `?${QueryParamsKeys.optRunId}=` + id;

  //
  return (
    <tr>
      <td>{serialNo}</td>
      <td>{name}</td>
      {/* <td>{}</td>  TODO : uncomment when needed*/}
      <td>{moment(timestamp).fromNow()}</td>
      <td>
        <ul className="list-unstyled list-inline my-1">
          <li className="list-inline-item">
            <Link className="btn btn-sm btn-outline-primary" to={OptRunSetupPageURL}>
              Open Setup
            </Link>
          </li>
          <li className="list-inline-item d-none">
            <Link className="btn btn-sm btn-outline-secondary" to={OptRunProblemPageURL}>
              View Problem
            </Link>
          </li>
        </ul>
      </td>
    </tr>
  );
}

function OptRunListTableBody({ projectId, optRunList = [], optRunListLoading = false }) {
  // Loader
  if (optRunListLoading) {
    return <TableLoader colSpan={5} />;
  }

  //if there is no Project list
  if (optRunList.length === 0) {
    return <TableDataNotFound message="No Objectives present" colSpan={5} />;
  }

  //Project  list table row
  return (
    <>
      {optRunList.map((optRun, index) => {
        return <OptRunListTableRow key={index} serialNo={index + 1} projectId={projectId} optRun={optRun} />;
      })}
    </>
  );
}

/**
 * Project List Table
 */
export default function OptRunListTable({ projectId, optRunList = [], optRunListLoading = false }) {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Project List Table Body */}
          <OptRunListTableBody projectId={projectId} optRunList={optRunList} optRunListLoading={optRunListLoading} />
        </tbody>
      </table>
    </div>
  );
}
