import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

// Components
import Modal from "../../../../components/modal/Modal";
import ExpressionInput from "../../../../components/input-expression/ExpressionInput";

//
// TODO Modal State to be reset after "onFormSubmit" Trigger
//
export default function GraphEquationModal({ modalId, graphId, nodeUid, equation = {}, variables = [], onSave }) {
  // Equation
  const { name = "", lhs = "", operator = "EQ", rhs = "" } = equation;

  // Component State
  const [formData, setformData] = useState({});

  // Set initial State
  useEffect(() => {
    setformData({ name: name, lhs: lhs, operator: operator, rhs: rhs });
  }, [name, lhs, operator, rhs]);

  //
  // Component Functions

  //
  const onInputChange = ({ target }) => {
    const inpName = target.name;
    const inpVal = target.value;

    // Update FormData
    setformData({ ...formData, [inpName]: inpVal });
  };

  //
  const onFormSubmit = (e) => {
    e.preventDefault(); // Prevent Default Submit

    // Trigger onSave callback
    onSave(formData);
  };

  function handleEquationChange(e, newValue, newPlainTextValue) {
    setformData({ ...formData, lhs: newPlainTextValue });
  }

  const varSymbols = useMemo(() => {
    // Symbols
    const varSymbols = variables //
      .filter(({ symbol }) => symbol && symbol.trim() !== "") //
      .map(({ symbol }) => ({ id: symbol, display: symbol }));

    return varSymbols;
  }, [variables]);

  //
  return (
    <Modal id={modalId} title="Node Equation">
      {/* Project Create Modal */}
      <form className="form-horizontal pb-3" onSubmit={onFormSubmit}>
        <div className="form-group mb-2">
          <label className="form-label">
            Equation Text <span className="text-danger">*</span> <em className="">(ex. x + y &lt;= 10)</em>
          </label>
          <div className="input-group">
            {/* Expression Input */}
            <ExpressionInput
              value={formData.lhs}
              suggestions={varSymbols}
              onChange={handleEquationChange}
              placeholder={"LHS"}
              containerClassName={"w-50"}
            />
            <select
              className="form-select"
              name="operator"
              value={formData?.operator || ""}
              required
              onChange={onInputChange}
            >
              <option value={"EQ"}>=</option>
              <option value={"GT"}>&gt;</option>
              <option value={"GT_EQ"}>&gt;=</option>
              <option value={"LT"}>&lt;</option>
              <option value={"LT_EQ"}>&lt;=</option>
            </select>
            <input
              className="form-control"
              type="number"
              name="rhs"
              value={formData?.rhs ?? "0"}
              placeholder="RHS"
              required
              onChange={onInputChange}
            ></input>
          </div>
        </div>

        <div className="form-group mb-2">
          <label className="form-label">Name (if any)</label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={formData?.name || ""}
            onChange={onInputChange}
          />
        </div>

        <div className="btn-cont mt-4 text-end">
          <button className="btn btn-sm btn-primary" type="submit" data-bs-dismiss="modal" aria-label="Close">
            Save Equation
          </button>
        </div>
      </form>
    </Modal>
  );
}
