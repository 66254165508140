import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/UpdateQueryParams";

// Constants
import { DefaultPagination, QueryParamsKeys } from "../../../constants/WebConstants";

// Actions
import { ProjectListActions } from "../../../redux-slice/project/ProjectListSlice";

//  Components
import TableHeaders from "../../../components/table/TableHeaders";
import TableLoader from "../../../components/table/TableLoader";
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TablePagination from "../../../components/table/TablePagination";
import Input from "../../../components/input/Input";

// URLs and App Utilities
import URLs from "../../../urls/URLs";
import PageURL from "../../../urls/PageURL";
import tableSerialNumber from "../../../utils/TableUtils";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "S.No.",
    },
  },
  {
    title: {
      displayName: "Project Name",
    },
  },
  {
    title: {
      displayName: "Created",
    },
  },
  {
    title: {
      displayName: "Modified",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

function ProjectListTableRow({ serialNo, project = {} }) {
  // Data
  const { id = "", name = "", description = "", createdOn = "", updatedOn = "" } = project;
  const ProjectCanvasURL = URLs.format(PageURL.ProjectCanvasPageURL, { projectId: id });

  return (
    <tr>
      <td>{serialNo}</td>
      <td>
        {name}
        <span className="sub-text">{description}</span>
      </td>
      <td>{moment(createdOn).format("MMM D, YYYY")}</td>
      <td>{moment(updatedOn).fromNow()}</td>
      <td>
        <Link className="btn btn-sm btn-outline-secondary" to={ProjectCanvasURL}>
          Open
        </Link>
      </td>
    </tr>
  );
}

function ProjectListTableBody({ pageNumber, pageSize, projectList = {}, projectListLoading }) {
  // Loader
  if (projectListLoading) {
    return <TableLoader colSpan={8} />;
  }

  // If there is no Project list
  if (projectList.length === 0) {
    return <TableDataNotFound message="No Project Present" colSpan={8} />;
  }

  // User Project list table row
  return (
    <>
      {projectList.map((project, index) => {
        // serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);
        // project List Table Row
        return <ProjectListTableRow key={project.id} serialNo={serialNo} project={project} />;
      })}
    </>
  );
}

function ProjectListPageActionsSection() {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder="Search Projects..." isSearchButton={true} />
    </div>
  );
}

/**
 * User Project List Table
 */
export default function ProjectListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Query Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Selector State
  const projectList = useSelector((state) => state.projectList.projectList);
  const projectListLoading = useSelector((state) => state.projectList.projectListLoading);
  const projectListPagination = useSelector((state) => state.projectList.projectListPagination);

  // From URL
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber) ?? DefaultPagination.pageNumber;
  const pageSize = searchParams.get(QueryParamsKeys.pageSize) ?? DefaultPagination.pageSize;
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  const { pageNo, totalCount } = projectListPagination || {};

  //use Effect
  useEffect(() => {
    dispatch(ProjectListActions.getProjectList({ searchText, pageNumber, pageSize }));
  }, [dispatch, searchText, pageNumber, pageSize]);

  return (
    <div className="page-content">
      <p className="my-3 fw-bold fs-5">All Projects</p>

      {/* Project List Page Actions Section */}
      <ProjectListPageActionsSection />

      <div className="table-responsive">
        <table className="table table-hover">
          {/* Table Headers */}
          <TableHeaders tableHeaders={tableHeaders} />

          <tbody>
            {/* Project List Table Body */}
            <ProjectListTableBody
              pageNumber={pageNumber}
              pageSize={pageSize}
              projectList={projectList}
              projectListLoading={projectListLoading}
            />
          </tbody>
        </table>

        <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
      </div>
    </div>
  );
}
