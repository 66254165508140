// Graph Parameter API URLs

//
const ParameterApiURL = {
  //
  CreateParameterURL: "/api/v1/graph/:graphId/parameter",
  UpdateParameterURL: "/api/v1/graph/:graphId/parameter/:parameterId",
  DeleteParameterURL: "/api/v1/graph/:graphId/parameter/:parameterId",

  //
};

// Export
export default ParameterApiURL;
