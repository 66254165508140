import { memo, useState } from "react";

import HandlesCont from "../connection/HandlesCont.jsx";
import GraphUtil from "../GraphUtil";
import { Position } from "reactflow";

// Constants
const PX_TOP_OFFSET = 35;
const PX_SPACING = 25;

// ConservationNode
// ----------------------------------------------------------------------------

function ConservationNode({ id, type, selected, data = {} }) {
  // Handles
  const sourceHandleIds = data.handles?.source || [];
  const sourceHandlesCount = sourceHandleIds.length;

  const targetHandleIds = data.handles?.target || [];
  const targetHandlesCount = targetHandleIds.length;

  // Parameters and Equations
  const parameters = data.parameters || [];
  const equations = data.equations || [];

  // Component State
  const [handlesCount, setHandlesCount] = useState(sourceHandlesCount + targetHandlesCount);
  const [bodyHeight, setBodyHeight] = useState(120);

  // Functions

  function addHandle(isInput) {
    //
  }

  return (
    <>
      <div className="node-header">
        <button className="btn btn-add-handle left" title="Add Input" onClick={() => addHandle(true)}>
          +
        </button>
        <div className="node-title">{data?.name}</div>
        <button className="btn btn-add-handle right" title="Add Output" onClick={() => addHandle(false)}>
          +
        </button>
      </div>
      <div className="node-body" style={{ height: bodyHeight }}>
        <div className="py-3"></div>
      </div>

      {/** Source Handles */}
      <HandlesCont type={"source"} handles={sourceHandleIds} topOffset={PX_TOP_OFFSET} spacing={PX_SPACING} />

      {/** Target Handles */}
      <HandlesCont type={"target"} handles={targetHandleIds} topOffset={PX_TOP_OFFSET} spacing={PX_SPACING} />
    </>
  );
}

export default memo(ConservationNode);
