import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { OptRunActions } from "../../redux-slice/project/OptRunSlice";
import { setErrorInfo } from "../../app/error/ErrorActions";

// Constants
import { QueryParamsKeys } from "../../constants/WebConstants";

// URLs
import PageURL from "../../urls/PageURL";

// APIs
import OptRunAPI from "../../api/project/OptRunAPI";
import URLs from "../../urls/URLs";

//
// Saga Functions
// ----------------------------------------------------------------------------

// Create Opt Run
function* createOptRun(action) {
  const { projectId, optRunObj, navigate } = action.payload;
  try {
    const { id = "" } = yield OptRunAPI.createOptRun(projectId, optRunObj);

    // Dispatching Action
    yield put(OptRunActions.createOptRunSuccess());

    // Toaster
    toast.success("Save & Run completed successfully");

    //
    // Navigate to OptRun View Page
    const pageURI = URLs.format(PageURL.ProjectOptSetupPageURL, { projectId });
    const pageURL = pageURI + `?${QueryParamsKeys.optRunId}=${id}`;
    navigate(pageURL);

    //
  } catch (error) {
    yield put(OptRunActions.createOptRunFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update Opt Run
function* updateOptRun(action) {
  const { projectId, optRunId, optRunObj } = action.payload;
  try {
    yield OptRunAPI.updateOptRun(projectId, optRunId, optRunObj);

    const optRun = yield OptRunAPI.getOptRunById(projectId, optRunId);

    // Dispatching Action
    yield put(OptRunActions.getOptRunSuccess({ optRun }));

    // Dispatching Action
    yield put(OptRunActions.updateOptRunSuccess());

    // Toaster
    toast.success("Updated successfully");
  } catch (error) {
    yield put(OptRunActions.updateOptRunFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Ger OptRun
function* getOptRun(action) {
  const { projectId, optRunId } = action.payload;
  try {
    const optRun = yield OptRunAPI.getOptRunById(projectId, optRunId);

    // Dispatching Action
    yield put(OptRunActions.getOptRunSuccess({ optRun }));
  } catch (error) {
    yield put(OptRunActions.getOptRunFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Ger OptRun Problem
function* getOptRunProblem(action) {
  const { projectId, optRunId, format } = action.payload;
  try {
    const optRunProblem = yield OptRunAPI.getOptRunProblemById(projectId, optRunId, format);

    // Dispatching Action
    yield put(OptRunActions.getOptRunProblemSuccess({ optRunProblem }));
  } catch (error) {
    yield put(OptRunActions.getOptRunProblemFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

//
// Export

export default function* root() {
  yield all([
    takeLatest(OptRunActions.createOptRun.type, createOptRun),

    takeLatest(OptRunActions.updateOptRun.type, updateOptRun),

    takeLatest(OptRunActions.getOptRun.type, getOptRun),
    takeLatest(OptRunActions.getOptRunProblem.type, getOptRunProblem),
  ]);
}
