// Graph Equation API URLs

//
const EquationApiURL = {
  //
  CreateEquationURL: "/api/v1/graph/:graphId/equation",
  UpdateEquationURL: "/api/v1/graph/:graphId/equation/:equationId",
  DeleteEquationURL: "/api/v1/graph/:graphId/equation/:equationId",

  //
};

// Export
export default EquationApiURL;
