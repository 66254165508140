import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Actions
import { ProjectActions } from "../../redux-slice/project/ProjectSlice";
import { ProjectListActions } from "../../redux-slice/project/ProjectListSlice";

// Constants and Utils
import { LocalStorageKeys } from "../../constants/GeneralConstants";
import { getItem } from "../../app/LocalStorage";

// Components
import Modal from "../../components/modal/Modal";

// Sections
import ProjectsListTable from "./ProjectListTable";

// Constants
const ID_MODAL_PROJECT_CREATE = "modal-project-create";

//
// Page Components
// ----------------------------------------------------------------------------

function ProjectCreateCard({ className = "" }) {
  return (
    <div
      className={`card cursor-pointer ${className}`}
      data-bs-toggle="modal"
      data-bs-target={`#${ID_MODAL_PROJECT_CREATE}`}
    >
      <div className="card-body">
        <h5 className="card-title">New Project</h5>
        <p className="text-muted">Create and Model new System</p>
      </div>
    </div>
  );
}

function ProjectImportCard({ className = "" }) {
  return (
    <div className={`card point-cursor ${className}`}>
      <div className="card-body">
        <h5 className="card-title">Import Model</h5>
        <p className="text-muted">Importing an existing model file.</p>
      </div>
    </div>
  );
}

// Create Project Modal Body Section
function ProjectCreateModal() {
  // Dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State
  const [formData, setformData] = useState({});

  // Input onChange
  function onInputChange({ target }) {
    const inpVal = target.value;
    const inpName = target.name;

    // Update FormData
    formData[inpName] = inpVal;

    setformData(formData);
  }

  // Form onSubmit
  function onFormSubmit(e) {
    e.preventDefault(); // Prevent Default

    // Get Auth User details and add it to form data
    const { userId } = JSON.parse(getItem(LocalStorageKeys.AUTH_USER));
    const projectData = {
      userId,
      ...formData,
    };

    dispatch(ProjectActions.createProject({ projectData, navigate }));
  }

  return (
    <Modal id={ID_MODAL_PROJECT_CREATE} title="Create a Project">
      {/* Project Create Modal */}
      <form className="form-horizontal pb-5" onSubmit={onFormSubmit}>
        <div className="form-group mb-2">
          <label className="form-label">
            Name <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            type="text"
            name="name"
            required
            onChange={onInputChange}
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-2">
          <label className="form-label">Description</label>
          <textarea className="form-control" name="description" onChange={onInputChange}></textarea>
        </div>

        <div className="btn-cont mt-3">
          <button className="btn btn-sm btn-secondary" type="submit" data-bs-dismiss="modal" aria-label="Close">
            Create
          </button>
        </div>
      </form>
    </Modal>
  );
}

/**
 * Page
 */
export default function DashboardPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Selector State
  const projectList = useSelector((state) => state.projectList.projectList);
  const projectListLoading = useSelector((state) => state.projectList.projectListLoading);

  //use Effect
  useEffect(() => {
    dispatch(ProjectListActions.getProjectList({ pageSize: 5, pageNumber: 1 }));
  }, [dispatch]);

  return (
    <>
      {/* Page Content */}
      <div className="page-content">
        {/* DashboardCards Section */}
        <div className="row">
          <div className="col-md-3">
            {/* Project Create Card */}
            <ProjectCreateCard className="" />
          </div>

          <div className="col-md-3">
            {/* Project Import Card */}
            <ProjectImportCard className="d-none" />
          </div>
        </div>

        {/** Recent Projects */}
        <div className="my-4 fw-bold fs-5">Recent Projects</div>
        <ProjectsListTable projectList={projectList} projectListLoading={projectListLoading} />
      </div>

      {/** Modals*/}
      <div className="">
        <ProjectCreateModal />
      </div>
    </>
  );
}
