import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Actions
import { AuthActions } from "../../redux-slice/auth/AuthSlice";

// Constants
import PageURL from "../../urls/PageURL";

// Components
import { Dropdown } from "../../components/dropdown/Dropdown";

// Page Components
function ProfileMenu() {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  const menuItems = [
    {
      buttonName: "Profile",
      buttonClassName: "border border-0",
      iconClassName: "fa-regular fa-user",
      onClick: () => {
        navigate(PageURL.ProfilePageURL);
      },
    },
    {
      buttonName: "Logout",
      buttonClassName: "border-top border-end-0 border-bottom-0 border-start-0 w-100 pe-5 mt-1",
      iconClassName: "fa-solid fa-power-off me-2 p-1 text-danger",
      onClick: () => {
        // Clear LocalStorage
        dispatch(AuthActions.resetLogin({ navigate }));
      },
    },
  ];

  return (
    // Dropdrown Component
    <Dropdown label="admin" imageUrl={""} parentClassName="bg-white rounded-pill p-1" dropDownItems={menuItems} />
  );
}

/**
 * Header Component
 */
export default function TopNav({ sidebarCollapsible = false, showSideBar, setShowSideBar }) {
  return (
    <header className={`topbar ${!showSideBar && "collapsed"}`}>
      <div className="d-flex">
        {sidebarCollapsible && ( //
          <button className="px-3 bg-transparent border-0" onClick={() => setShowSideBar(!showSideBar)}>
            <i className="fa fa-fw fa-bars" />
          </button>
        )}
        <div className="">{/** Left Menu */}</div>
      </div>
      <div className="">{/** Center Menu */}</div>
      <div className="me-4">
        {/** Right Menu */}
        <ProfileMenu />
      </div>
    </header>
  );
}
