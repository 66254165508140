// Constants
import { DefaultPagination } from "../constants/WebConstants";

/**
 * Table Serial Number Function
 * @param {*} pageNumber : Current page number
 * @param {*} pageSize : Current page size
 * @param {*} index : Index from map
 */

export default function tableSerialNumber(
  pageNumber = DefaultPagination.pageNumber,
  pageSize = DefaultPagination.pageSize,
  index = 0
) {
  return (pageNumber - 1) * pageSize + (index + 1);
}
