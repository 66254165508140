import { Error } from "./ErrorActionConstants";

// Set error information
export const setErrorInfo = ({ errorInfo, showToaster = false, showPageError = false }) => {
  return {
    type: Error.SET_ERROR_INFO,
    payload: { errorInfo, showToaster, showPageError },
  };
};

// Clear error information
export const clearErrorInfo = () => {
  return {
    type: Error.CLEAR_ERROR_INFO,
  };
};
