// Utils
import URLs from "../../urls/URLs";

// URLs
import OptRunApiURL from "../../urls/api-urls/project/OptRunApiURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

//
// API Functions
// ----------------------------------------------------------------------------

// Create Opt Run
const createOptRun = async function (projectId, optRunObj) {
  // API URL
  const apiURL = URLs.format(OptRunApiURL.CreateOptRunURL, { projectId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, optRunObj);
};

// Update Opt Run
const updateOptRun = async function (projectId, optRunId, optRunObj) {
  // API URL
  const apiURL = URLs.format(OptRunApiURL.UpdateOptRunByIdURL, { projectId, optRunId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, {}, optRunObj);
};

// Get Opt Run By Id
const getOptRunById = async function (projectId, optRunId) {
  // API URL
  const apiURL = URLs.format(OptRunApiURL.GetOptRunByIdURL, { projectId, optRunId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL);
};

// Get Opt Run By Id
const getOptRunProblemById = async function (projectId, optRunId, format = "") {
  // Params
  const params = {
    _f: format,
  };

  // API URL
  const apiURL = URLs.format(OptRunApiURL.GetOptRunProblemByIdURL, { projectId, optRunId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL, params);
};

//
// Export
// ----------------------------------------------------------------------------

const OptRunAPI = {
  //
  createOptRun,

  //
  updateOptRun,

  //
  getOptRunById,
  getOptRunProblemById,
};

export default OptRunAPI;
