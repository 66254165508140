//
// Component

// Constants
const MODAL_SIZE = {
  sm: "modal-sm",
  lg: "modal-lg",
  xl: "modal-xl",
};

/**
 * Modal Component
 * @param {*} id : To toggle each modal
 * @param {*} title : Modal title
 * @param {*} size : Modal size
 */
export default function Modal({ className = "", id = "", title = "", size = "", children }) {
  // Modal Size
  const modalSize = MODAL_SIZE[size || ""];

  return (
    <div className={`modal fade ${className}`} id={id} aria-hidden="true">
      <div className={`modal-dialog ${modalSize}`}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            {/** body */}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
