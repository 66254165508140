/**
 * Table Data Not Found Component
 * @param {*} message : Message for empty data table
 */
export default function TableDataNotFound({ message, colSpan }) {
  return (
    <tr className="text-center">
      <td colSpan={colSpan}>
        <span>{message}</span>
      </td>
    </tr>
  );
}
