import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { ProjectListActions } from "../../redux-slice/project/ProjectListSlice";
import { setErrorInfo } from "../../app/error/ErrorActions";

// APIs
import ProjectListAPI from "../../api/project/ProjectListAPI";

//
// Saga Functions
// ----------------------------------------------------------------------------

// Get Project list
function* getProjectList(action) {
  try {
    const { searchText, pageNumber, pageSize } = action.payload;

    const { items, pagination } = yield ProjectListAPI.searchProjects(searchText, pageNumber, pageSize);

    // Dispatching Action
    yield put(
      ProjectListActions.getProjectListSuccess({
        projectList: items,
        projectListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ProjectListActions.getProjectListFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Export
export default function* root() {
  yield all([
    //
    takeLatest(ProjectListActions.getProjectList.type, getProjectList),
  ]);
}
