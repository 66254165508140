import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";

// Actions
import { OptRunListActions } from "../../../redux-slice/project/OptRunListSlice";
import { ProjectActions } from "../../../redux-slice/project/ProjectSlice";

// Utils
import PageURL from "../../../urls/PageURL";
import URLs from "../../../urls/URLs";

import { QueryParamsKeys } from "../../../constants/WebConstants";

// Components
import PageHeader from "../../../app/layout/PageHeader";

// Components :: Graph / Reactflow
import GraphConfig from "../../../reactflow/GraphConfig";

//
import ProjectSidebarLeft from "../ProjectSidebarLeft";
import ProjectTopbar from "../ProjectTopbar";
import OptRunListTable from "./OptRunListTable";

//
// Constants
const NodeTypeNameMap = GraphConfig.NodeTypeNameMap;
const VarTypeNameMap = GraphConfig.VarTypeNameMap;

//
// Page Components
// ----------------------------------------------------------------------------

//
function PageHeaderSection({ projectInfo = {} }) {
  //
  const { id, name } = projectInfo;

  // URLs
  const projectOptSetupPageURL = URLs.format(PageURL.ProjectOptSetupPageURL, { projectId: id, optRunId: "" });

  // Content
  const pageTitle = "Optimization Objectives";
  const pageActions = (
    <div className="btn-cont">
      <Link className="btn btn-outline-primary" to={projectOptSetupPageURL}>
        <i className="fa fa-chart-area me-2"></i> Setup new Objective
      </Link>
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} />;
}

//

/**
 * Page
 */
export default function ProjectOptListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Path Params
  const params = useParams();
  const { projectId } = params;

  // Page Query Params
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);

  // Dispatch API calls
  useEffect(() => {
    dispatch(ProjectActions.getProjectInfo({ projectId }));
  }, [projectId, dispatch]);

  useEffect(() => {
    dispatch(OptRunListActions.getOptRunList({ projectId, pageNumber, pageSize }));
  }, [projectId, pageNumber, pageSize, dispatch]);

  //
  // Selector State
  const projectInfo = useSelector((state) => state.project.projectInfo);

  const optRunList = useSelector((state) => state.optRunList.optRunList);
  const optRunListLoading = useSelector((state) => state.optRunList.optRunListLoading);

  //
  // Data

  //
  return (
    <>
      {/* Topbar */}
      <ProjectTopbar projectInfo={projectInfo} />

      {/* Sidebar Left */}
      <ProjectSidebarLeft projectInfo={projectInfo} selection={"opt"} />

      <div className="main-cont position-right-0">
        <div className="content-wrapper">
          {/** Header */}
          <PageHeaderSection projectInfo={projectInfo} />

          {/* Page Content */}
          <div className="page-content">
            {/** */}
            <div className="py-3 pb-5">
              <OptRunListTable projectId={projectId} optRunList={optRunList} optRunListLoading={optRunListLoading} />
            </div>

            {/** */}
          </div>
        </div>
      </div>
    </>
  );
}
