// Graph API URLs

//
const GraphApiURL = {
  // get Data by ID
  GetDataByIdURL: "/api/v1/graph/:graphId",

  // Process (auto-generation etc.)
  RefreshGraphSetupURL: "/api/v1/graph/:graphId/refresh",

  //
};

// Export
export default GraphApiURL;
