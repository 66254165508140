import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

// Actions
import { GraphActions } from "../../../redux-slice/graph/GraphSlice";
import { OptRunActions } from "../../../redux-slice/project/OptRunSlice";
import { ProjectActions } from "../../../redux-slice/project/ProjectSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/WebConstants";

// Utils

// Components
import PageHeader from "../../../app/layout/PageHeader";
import Loader from "../../../components/loader/Loader";

// Section
import ProjectSidebarLeft from "../ProjectSidebarLeft";
import ProjectTopbar from "../ProjectTopbar";

//
// Page Components
// ----------------------------------------------------------------------------

//
function PageHeaderSection({ projectInfo = {}, optRunId }) {
  // Content
  const pageTitle = "Optimization Problem (AMPL Format)";
  const pageActions = (
    <div className="btn-cont">
      {/* Save And Run Button */}
      {/** */}
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} />;
}

/**
 * Page
 */
export default function ProjectOptProblemPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const { projectId } = useParams();
  const graphId = projectId; // graphId is just "projectId"

  // Project Info Selector State
  const projectInfo = useSelector((state) => state.project.projectInfo);

  // Opt Run Selector State
  const optRunProblem = useSelector((state) => state.optRun.optRunProblem);

  // Generate Equation Loading State
  const refreshGraphLoading = useSelector((state) => state.graph.refreshGraphLoading);

  // Page Query Params
  const [searchParams, setSearchParams] = useSearchParams();
  const optRunId = searchParams.get(QueryParamsKeys.optRunId) ?? "";

  // Dispatch API calls
  useEffect(() => {
    dispatch(ProjectActions.getProjectInfo({ projectId }));
    dispatch(GraphActions.refreshGraph({ graphId }));
  }, [projectId, graphId, dispatch]);

  useEffect(() => {
    if (optRunId) {
      dispatch(OptRunActions.getOptRunProblem({ projectId, optRunId }));
    }
  }, [projectId, optRunId, dispatch]);

  //
  return (
    <>
      {/* Topbar */}
      <ProjectTopbar projectInfo={projectInfo} />

      {/* Sidebar Left */}
      <ProjectSidebarLeft projectInfo={projectInfo} selection={"opt"} />

      {/* Page Content */}
      <div className="main-cont position-right-0">
        {refreshGraphLoading && ( //
          <Loader containerClassName="input-group-text" />
        )}

        {!refreshGraphLoading && (
          <div className="content-wrapper">
            {/** Header */}
            <PageHeaderSection projectInfo={projectInfo} optRunId={optRunId} />

            {/* Page Content */}
            <div className="page-content">
              <div className="py-3 pb-5">
                <p style={{ whiteSpace: "pre-line", fontSize: "16px" }}>{optRunProblem}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
