/**
 * Custom Text Manipulation Utilities
 */

//
// Conversion
// ----------------------------------------------------------------------------

const toShortSymbol = (text = "", upperCase = true) => {
  const symbol = text
    .trim() // clear leading and trailing spaces
    .replace("_", " ") // replace special chars with space
    .replace(/\s+/g, " ") // reduce multiple spaces to single
    .split(" ") // split by space
    .map((w) => w.charAt(0)) // get the first char of each word
    .join("");

  // upper-case
  return upperCase ? symbol.toUpperCase() : symbol;
};

//
// Export
// ----------------------------------------------------------------------------

const TextUtil = {
  //
  toShortSymbol,
};

export default TextUtil;
