import React from "react";
import { useDispatch } from "react-redux";

// Actions
import { EdgeActions } from "../../../redux-slice/graph/EdgeSlice";

// Components
import InlineEdit from "../../../components/inline-edit/InlineEdit";

// Utils
import GraphUtil from "../../../reactflow/GraphUtil";
import ReactFlowExt from "../../../reactflow/ReactFlowExt";

// Config
import GraphConfig from "../../../reactflow/GraphConfig";

// Constants
const NodeTypeNameMap = GraphConfig.NodeTypeNameMap;

const EdgeTypeNameMap = GraphConfig.EdgeTypeNameMap;
const EdgeTypes = Object.keys(EdgeTypeNameMap);

const VarTypeNameMap = GraphConfig.VarTypeNameMap;
const EdgeVarTypes = GraphConfig.EdgeVarTypes;

//
// Component
// ----------------------------------------------------------------------------

export default function SectionEdgeInfo({ graphId, edge = {}, sourceNode = {}, targetNode = {} }) {
  // dispatch
  const dispatch = useDispatch();

  // Edge Info
  const edgeUid = edge.id;
  const edgeType = edge.type;
  const edgeTypeName = EdgeTypeNameMap[edgeType];

  // Data :: Var
  const edgeData = edge.data || {};
  const varType = edgeData.varType;
  const varName = edgeData.name;
  const varSymbol = edgeData.symbol;

  //
  // Component Functions

  const updateEdgeData = ReactFlowExt.useUpdateEdgeData(edgeUid);

  // Type
  const onEdgeVarTypeChange = React.useCallback(
    ({ target }) => {
      const varType = target.value;

      // Data Updates
      const newName = VarTypeNameMap[varType];
      const varInfo = { varType, name: newName, symbol: varSymbol };

      // Dispatch Edge Update Action
      dispatch(EdgeActions.updateEdgeVarInfo({ graphId, edgeUid, varInfo }));

      // Update Edge Type and Data
      updateEdgeData(varInfo);
    },
    [graphId, edgeUid, varSymbol, updateEdgeData, dispatch]
  );

  // Name
  const onEdgeNameChange = React.useCallback(
    (newName) => {
      // Data Updates
      const varInfo = { name: newName, symbol: varSymbol };

      // Dispatch Edge Update Action
      dispatch(EdgeActions.updateEdgeVarInfo({ graphId, edgeUid, varInfo }));

      // callback
      updateEdgeData(varInfo);
    },
    [graphId, edgeUid, varSymbol, updateEdgeData, dispatch]
  );

  //
  return (
    <>
      <div className="sec-info p-3">
        <h6 className="px-1 mb-2 d-flex justify-content-between">
          <span>Edge</span>
          <small className="text-secondary">({edgeTypeName})</small>
        </h6>
      </div>

      <div className="sec-info px-3 py-2">
        <div className="row">
          <label className="col-3 col-form-label">Name</label>
          <div className="col-9">
            <InlineEdit className="form-control" value={varName || "--"} setValue={onEdgeNameChange} />
          </div>
        </div>

        <div className="row">
          <label className="col-3 col-form-label">Type</label>
          <div className="col-9">
            <select className="form-select form-select-sm border-0 px-2" onChange={onEdgeVarTypeChange} value={varType}>
              <option value={""}>--</option>
              {EdgeVarTypes.map((vt) => {
                const varTypeName = VarTypeNameMap[vt];
                return (
                  <option key={vt} value={vt}>
                    {varTypeName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="row mt-2">
          <label className="col-3 col-form-label text-secondary">Variable</label>
          <div className="col-9">
            <label className="p-2 text-secondary">{varSymbol || "--"}</label>
          </div>
        </div>
      </div>

      <div className="sec-info p-3">
        <p className="mb-3">Connecting</p>

        {/** Connected Nodes :: Source */}
        <div className="my-2 d-flex justify-content-between">
          <p className="mb-1 fw-bold">Source</p>
          <p>
            <span>{sourceNode?.data?.name}</span>
            <small className="text-secondary ms-2">({NodeTypeNameMap[sourceNode?.type]})</small>
          </p>
        </div>

        {/** Connected Nodes :: Target */}
        <div className="my-2 d-flex justify-content-between">
          <p className="mb-1 fw-bold">Target</p>
          <p>
            <span>{targetNode?.data?.name}</span>
            <small className="text-secondary ms-2">({NodeTypeNameMap[targetNode?.type]})</small>
          </p>
        </div>
      </div>
    </>
  );
}
