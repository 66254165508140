// Project API URLs

//
const ProjectApiURL = {
  // CRUD
  CreateProjectURL: "/api/v1/project",
  UpdateProjectInfoURL: "/api/v1/project/:projectId",
  GetProjectInfoURL: "/api/v1/project/:projectId",

  // Search
  SearchProjectsURL: "/api/v1/project/search",
};

// Export
export default ProjectApiURL;
