import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  Panel,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";

// Actions
import { EdgeActions } from "../../../redux-slice/graph/EdgeSlice";
import { GraphActions } from "../../../redux-slice/graph/GraphSlice";
import { NodeActions } from "../../../redux-slice/graph/NodeSlice";
import { ProjectActions } from "../../../redux-slice/project/ProjectSlice";

// Components
import ProjectTopbar from "../ProjectTopbar";
import CanvasSidebarLeft from "./CanvasSidebarLeft";
import CanvasSidebarRight from "./CanvasSidebarRight";

// Components :: Graph / Reactflow
import GraphConfig from "../../../reactflow/GraphConfig";
import GraphUtil from "../../../reactflow/GraphUtil";
import ConnectionLine, { ConnectionLineStyle } from "../../../reactflow/connection/ConnectionLine";

//
// Constants
const NodeType = GraphConfig.NodeType;
const EdgeType = GraphConfig.EdgeType;

const defaultEdgeOptions = {
  style: { strokeWidth: 2, stroke: "black" },
  animated: true,
  type: "floating",
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: "black",
  },
};

// Constants :: Var
const PREFIX_VAR = "x";

//
// Page Components
// ----------------------------------------------------------------------------

/**
 * Page
 */
export default function ProjectCanvasPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const params = useParams();
  const { projectId } = params;
  const graphId = projectId; // graphId is just "projectId"

  //
  // Page State
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  //
  // Selector State
  const projectInfo = useSelector((state) => state.project.projectInfo);

  const graphData = useSelector((state) => state.graph.graphData);
  const graphDataLoading = useSelector((state) => state.graph.graphDataLoading);

  const { parametersMap = {}, equationsMap = {} } = graphData || {};

  const graphWarnings = useSelector((state) => state.graph.graphWarnings);
  const graphErrors = useSelector((state) => state.graph.graphErrors);
  const graphValidationSuccess = useSelector((state) => state.graph.graphValidationSuccess);
  const graphValidationLoading = useSelector((state) => state.graph.graphValidationLoading);

  //
  // Selector State :: Re-render once the GraphData is ready
  useEffect(() => {
    const { nodes: rfNodes, edges: rfEdges } = GraphUtil.toReactFlowFormat(graphId, graphData);

    // Update Reactflow
    setNodes(rfNodes);
    setEdges(rfEdges);
  }, [graphId, graphData, setNodes, setEdges]);

  //
  // Dispatch API calls

  useEffect(() => {
    dispatch(ProjectActions.getProjectInfo({ projectId }));
    dispatch(GraphActions.getGraphData({ graphId }));
  }, [projectId, graphId, dispatch]);

  //
  // Page Data
  // --------------------------------------------------------------------------

  const { nodesMap } = React.useMemo(() => {
    // Create Map ::  Reduce By ID
    const nodesMap = nodes?.reduce((map, obj) => {
      map[obj.id] = obj;
      return map;
    }, {});

    return { nodesMap };
  }, [nodes]);

  const handleUids = React.useMemo(() => {
    // Create Array of Handle UIDs
    const handleUids = edges?.reduce((arr, edge) => {
      // Edge Info
      const { source, sourceHandle, target, targetHandle } = edge;
      const srcHandleUid = GraphUtil.toHandleUid(source, sourceHandle);
      const tarHandleUid = GraphUtil.toHandleUid(target, targetHandle);

      return [...arr, srcHandleUid, tarHandleUid];
    }, []);

    return handleUids;
  }, [edges]);

  //
  // ID generator related (pre-compute for other utils)

  // Nodes Summary
  const { nodesCount, lastNodeIdx } = React.useMemo(() => {
    const nodesCount = nodes.length;

    const nodeIds = nodes.map((e) => e.id);
    const sortedNodeIdx = nodeIds //
      .map((sym) => Number(sym.substring(1)))
      .sort((a, b) => a - b);

    const lastNodeIdx = nodesCount === 0 ? 0 : sortedNodeIdx[sortedNodeIdx.length - 1];

    return { nodesCount, lastNodeIdx };
  }, [nodes]);

  // Edges Summary
  const { edgesCount, lastVarIdx } = React.useMemo(() => {
    const edgesCount = edges.length;

    const varSymbols = edges.map((e) => e.data?.symbol);
    const sortedVarIdx = varSymbols //
      .filter((sym) => !!sym) // https://stackoverflow.com/questions/28607451/removing-undefined-values-from-array
      .map((sym) => Number(sym.substring(1)))
      .sort((a, b) => a - b);

    const lastVarIdx = edgesCount === 0 ? 0 : sortedVarIdx[sortedVarIdx.length - 1];

    return { edgesCount, lastVarIdx };
  }, [edges]);

  //
  // Page Functions
  // --------------------------------------------------------------------------

  // Graph Validation
  const dispatchGraphValidation = () => {
    dispatch(GraphActions.validateGraph({ graphId, nodes, edges, parametersMap, equationsMap }));
  };

  //
  // Node :: add
  const addNewNode = React.useCallback(
    (nodeType) => {
      // Prepare new Node
      const newNode = GraphUtil.prepareNewNode(nodeType, lastNodeIdx, nodesCount);

      // Updates Nodes State
      setNodes((nds) => {
        return [...nds, newNode];
      });

      // Dispatch Add Node Action
      dispatch(NodeActions.createNode({ graphId, node: newNode }));
    },
    [graphId, nodesCount, lastNodeIdx, dispatch, setNodes]
  );

  //
  // Node :: position
  const saveNodePosition = React.useCallback(
    (event, node) => {
      // Position
      const { id, position } = node;
      const { x, y } = position;

      // Dispatch Node Pos Update Action
      dispatch(NodeActions.updateNodePosition({ graphId, nodeUid: id, x, y }));
    },
    [graphId, dispatch]
  );

  //
  // Node :: delete
  const deleteNode = React.useCallback(
    (nodes = []) => {
      // Iterate over Nodes
      nodes.forEach(({ id }) => {
        // Dispatch Node Delete Action
        dispatch(NodeActions.deleteNode({ graphId, nodeUid: id }));
      });
    },
    [graphId, dispatch]
  );

  //
  // Edge :: add
  const onConnect = React.useCallback(
    (params) => {
      const { source, sourceHandle, target, targetHandle } = params;
      const edgeUid = GraphUtil.toEdgeId(source, sourceHandle, target, targetHandle);

      // var
      const nextVarIdx = lastVarIdx + 1;
      const varSymbol = PREFIX_VAR + "" + nextVarIdx;

      // edge
      const newEdge = {
        ...params,
        id: edgeUid,
        type: EdgeType.FLOW,
        data: {
          name: varSymbol,
          symbol: varSymbol,
        },
      };

      // TODO : Move this to Saga
      // Updates Nodes State
      setEdges((eds) => addEdge(newEdge, eds));

      // Dispatch Add Edge Action
      dispatch(EdgeActions.createEdge({ graphId, edge: newEdge }));
    },
    [graphId, lastVarIdx, setEdges, dispatch]
  );

  //
  // Edge :: delete
  const deleteEdge = React.useCallback(
    (edges = []) => {
      // Iterate over Edges
      edges.forEach(({ id }) => {
        // Dispatch Edge Delete Action
        dispatch(EdgeActions.deleteEdge({ graphId, edgeUid: id }));
      });
    },
    [graphId, dispatch]
  );

  //
  // Connection :: Validation
  const isValidConnection = React.useCallback(
    (connection) => {
      // Connection Related info
      const { source: srcNodeId, sourceHandle: srcHandleId, target: tarNodeId, targetHandle: tarHandleId } = connection;

      // Source, Target Node Info
      const { type: srcNodeType } = nodesMap[srcNodeId];
      const { type: tarNodeType } = nodesMap[tarNodeId];

      // 01 : Self Connection is not allowed
      if (srcNodeId === tarNodeId) {
        return false;
      }

      // 02 : Source to Sink Connection is not allowed
      if (srcNodeType === NodeType.SOURCE && tarNodeType === NodeType.SINK) {
        return false;
      }

      // 03 : Mutliple connections from same handle are not allowed
      const srcHandleUid = GraphUtil.toHandleUid(srcNodeId, srcHandleId);
      if (handleUids.includes(srcHandleUid)) {
        return false;
      }

      const tarHandleUid = GraphUtil.toHandleUid(tarNodeId, tarHandleId);
      if (handleUids.includes(tarHandleUid)) {
        return false;
      }

      return true;
    },
    [nodesMap, handleUids]
  );

  //
  return (
    <ReactFlowProvider>
      {/* Topbar */}
      <ProjectTopbar
        projectInfo={projectInfo}
        mode={"canvas"}
        validationLoading={graphValidationLoading}
        dispatchGraphValidation={dispatchGraphValidation}
      />

      {/* Sidebar Left */}
      <CanvasSidebarLeft
        graphId={graphId}
        nodes={nodes}
        warnings={graphWarnings}
        errors={graphErrors}
        validationLoading={graphValidationLoading}
      />

      {/* Sidebar Right */}
      <CanvasSidebarRight
        graphId={graphId}
        nodes={nodes}
        edges={edges}
        parametersMap={parametersMap}
        equationsMap={equationsMap}
      />

      {/* Main Content */}
      <div className="main-cont canvas-cont">
        <div className="content-wrapper">
          {/** Reactflow Canvas */}
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            fitView={true}
            snapGrid={true}
            nodeTypes={GraphConfig.NodeTypeComponentMap}
            edgeTypes={GraphConfig.EdgeTypeComponentMap}
            connectionLineComponent={ConnectionLine}
            connectionLineStyle={ConnectionLineStyle}
            defaultEdgeOptions={defaultEdgeOptions}
            isValidConnection={isValidConnection}
            onNodeDragStop={saveNodePosition}
            onNodesDelete={deleteNode}
            onEdgesDelete={deleteEdge}
            attributionPosition="bottom-left"
            deleteKeyCode={["Delete"]}
            minZoom={0.01}
          >
            <Background variant="dots" gap={12} size={1} />
            <Controls position="bottom-right" />

            {/** Menu Panel */}
            <Panel position="top-left">
              <div
                className="panel-item"
                onClick={() => addNewNode(NodeType.CONTROL_VOLUME)}
                title="Add new Control Volume Node"
              >
                <i className="far fa-square"></i>
              </div>
              <div className="panel-item" onClick={() => addNewNode(NodeType.SOURCE)} title="Add new Source Node">
                <i className="fas fa-sign-out"></i>
              </div>
              <div className="panel-item" onClick={() => addNewNode(NodeType.SINK)} title="Add new Sink Node">
                <i className="fas fa-sign-in"></i>
              </div>
            </Panel>

            {/** */}
          </ReactFlow>
        </div>
      </div>
    </ReactFlowProvider>
  );
}
