import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Actions
import { GraphActions } from "../../../redux-slice/graph/GraphSlice";
import { OptRunActions } from "../../../redux-slice/project/OptRunSlice";
import { ProjectActions } from "../../../redux-slice/project/ProjectSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/WebConstants";

// Utils
import DecimalUtils from "../../../utils/DecimalUtils";

// Components
import { Button } from "../../../components/button/Button";
import PageHeader from "../../../app/layout/PageHeader";
import Loader from "../../../components/loader/Loader";

// Section
import ProjectSidebarLeft from "../ProjectSidebarLeft";
import ProjectTopbar from "../ProjectTopbar";
import BoundsCard from "./BoundsCard";
import ObjectiveFunctionCard from "./ObjectiveFunctionCard";

//
// Page Components
// ----------------------------------------------------------------------------

//
function PageHeaderSection({ projectInfo = {}, objectiveInfo = {}, boundsData = {}, optRunId }) {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Selector State
  const createOptRunLoading = useSelector((state) => state.optRun.createOptRunLoading);
  const updateOptRunLoading = useSelector((state) => state.optRun.updateOptRunLoading);

  // Btn Status
  const btnStatus = createOptRunLoading || updateOptRunLoading;

  // Project Information
  const { id = "" } = projectInfo;

  // Objective Information
  const { name = "", isMinimize = true, costExpression = "" } = objectiveInfo;

  // Save and Run
  function saveAndRun() {
    // Objective Array
    const objectivesArray = [{ name, isMinimize, costExpression }];

    // Var Bounds
    const varFixedValues = {};
    const varMinBounds = {};
    const varMaxBounds = {};

    // Constructing var values
    Object.keys(boundsData).forEach((varSymbol) => {
      const { value = "", min = "", max = "" } = boundsData[varSymbol] || {};

      // Parsing min, max and value
      const parsedValue = parseFloat(value) ?? "";
      const parsedMin = parseFloat(min) ?? "";
      const parsedMax = parseFloat(max) ?? "";

      // TODO: Try to handle zero value, instead of converting to string in the condition.
      // Checking for Nan and Zero
      if (!isNaN(parsedValue) && `${parsedValue}`) {
        varFixedValues[varSymbol] = parsedValue;
      } else {
        if (!isNaN(parsedMin) && `${parsedMin}`) {
          varMinBounds[varSymbol] = parsedMin;
        }
        if (!isNaN(parsedMax) && `${parsedMax}`) {
          varMaxBounds[varSymbol] = parsedMax;
        }
      }
    });

    // Form Data
    const optRunObj = {
      projectId: id,
      graphId: id, // TODO accept as a Param
      name,
      objectives: objectivesArray,
      varFixedValues,
      varMinBounds,
      varMaxBounds,
      // ...varValues,
    };

    // If its updating the existing opt run
    if (optRunId) {
      // Dispatch
      dispatch(OptRunActions.updateOptRun({ optRunObj, projectId: id, optRunId }));
      return;
    }

    // If its creating a new opt run
    // Dispatch
    dispatch(OptRunActions.createOptRun({ optRunObj, projectId: id, navigate }));
  }

  // Content
  const pageTitle = "Optimization Problem Setup";
  const pageActions = (
    <div className="btn-cont">
      {/* Save And Run Button */}
      <Button label="Save and Run" onClick={saveAndRun} loading={btnStatus} disabled={btnStatus}>
        <i className="fa fa-play me-2"></i>
      </Button>
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} />;
}

function ObjectiveFunctionSolutionCard({ optRunId = "" }) {
  // Opt Run Selector State
  const optRun = useSelector((state) => state.optRun.optRun);

  // Objective Cost Information
  const { objectivesCost = {}, statusMsg = "" } = optRun || {};
  const { obj00 = "" } = objectivesCost;

  // If opt run id is not present
  if (!optRunId) {
    return null;
  }

  return (
    <div className="card card-info mt-4 bg-warning-subtle">
      <div className="card-body">
        <div className="row">
          <div className="col-5 fst-italic">{statusMsg}</div>
          <div className="col-7">
            <span className="fw-bold me-2">Objective Cost:</span> {DecimalUtils.fixDecimal(obj00)}
          </div>
        </div>
      </div>
    </div>
  );
}

//
function ConstraintsCard({ equations = [] }) {
  //
  return (
    <div className="card card-info">
      <div className="card-body">
        <h6 className="py-1 pb-2 mb-3 border-bottom fw-bold">Subject to:</h6>
        <ul className="list-unstyled list-graph-elems">
          {equations.map((eq, idx) => {
            const { id, name, text } = eq;
            //
            return (
              <li className="" key={`key-eqn-${id}`}>
                {text}
                <small className="text-secondary ms-2 fst-italic">{name}</small>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

//
function constructFormData(data = [], optRun = {}) {
  // Opt Run
  const { varFixedValues = {}, varMinBounds = {}, varMaxBounds = {}, solution = {} } = optRun;

  return data.reduce((acc, eachData) => {
    const { symbol = "" } = eachData || {};

    const obj = {
      min: varMinBounds[symbol] ?? "",
      max: varMaxBounds[symbol] ?? "",
      value: varFixedValues[symbol] ?? "",
      solution: solution[symbol] ?? "",
    };

    return { ...acc, [symbol]: obj };
  }, {});
}

/**
 * Page
 */
export default function ProjectOptSetupPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const { projectId } = useParams();
  const graphId = projectId; // graphId is just "projectId"

  // State
  const [boundsData, setBoundsData] = useState({});
  const [objectiveInfo, setObjectiveInfo] = useState("");

  // Project Info Selector State
  const projectInfo = useSelector((state) => state.project.projectInfo);

  // Graph Data Selector State
  const graphData = useSelector((state) => state.graph.graphData);

  // Opt Run Selector State
  const optRun = useSelector((state) => state.optRun.optRun);

  // Generate Equation Loading State
  const refreshGraphLoading = useSelector((state) => state.graph.refreshGraphLoading);

  // Page Query Params
  const [searchParams, setSearchParams] = useSearchParams();
  const optRunId = searchParams.get(QueryParamsKeys.optRunId) ?? "";

  //
  // Graph Data
  const { info: graphInfo, edges = [], parameters = [], equations = [] } = graphData || {};
  const { edgeData = {}, parameterData = {} } = graphInfo || {};

  // Objective Information
  const { objectives = [] } = optRun || {};
  const objectivesStr = JSON.stringify(objectives);

  // Dispatch API calls
  useEffect(() => {
    dispatch(ProjectActions.getProjectInfo({ projectId }));
    dispatch(GraphActions.getGraphData({ graphId }));
    dispatch(GraphActions.refreshGraph({ graphId }));
  }, [projectId, graphId, dispatch]);

  useEffect(() => {
    if (optRunId) {
      dispatch(OptRunActions.getOptRun({ projectId, optRunId }));
    }
  }, [projectId, optRunId, dispatch]);

  useEffect(() => {
    if (optRunId) {
      const objInfo = objectives[0] || {};
      setObjectiveInfo(objInfo);
    } else {
      setObjectiveInfo({});
    }
  }, [optRunId, objectivesStr, objectives]);

  useEffect(() => {
    if (optRunId) {
      // Combining data of parameters and edges
      const data = [...parameters, ...edges];

      setBoundsData(constructFormData(data, optRun));
    } else {
      setBoundsData({});
    }
  }, [optRun, optRunId]);

  //
  return (
    <>
      {/* Topbar */}
      <ProjectTopbar projectInfo={projectInfo} />

      {/* Sidebar Left */}
      <ProjectSidebarLeft projectInfo={projectInfo} selection={"opt"} />

      {/* Page Content */}
      <div className="main-cont position-right-0">
        {refreshGraphLoading && ( //
          <Loader containerClassName="input-group-text" />
        )}

        {!refreshGraphLoading && (
          <div className="content-wrapper">
            {/** Header */}
            <PageHeaderSection
              projectInfo={projectInfo}
              objectiveInfo={objectiveInfo}
              boundsData={boundsData}
              optRunId={optRunId}
            />

            {/* Page Content */}
            <div className="page-content">
              {/** Objective Function */}
              <ObjectiveFunctionCard
                edges={edges}
                parameters={parameters}
                objectiveInfo={objectiveInfo}
                setObjectiveInfo={setObjectiveInfo}
              />

              {/* Objective Function Solution Card */}
              <ObjectiveFunctionSolutionCard optRunId={optRunId} />

              <div className="row my-4">
                <div className="col-5">
                  {/** Constraints */}
                  <ConstraintsCard equations={equations} />
                </div>
                <div className="col-7">
                  {/** Bounds */}
                  <BoundsCard boundsData={boundsData} setBoundsData={setBoundsData} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
