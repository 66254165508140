/* eslint-disable import/no-anonymous-default-export */

//Action Constants
import { Error } from "./ErrorActionConstants";

const initialState = {
  // Error
  errorInfo: {},
  showToaster: false,
  showPageError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Error
    case Error.SET_ERROR_INFO:
      const { errorInfo, showToaster, showPageError } = action.payload;

      return {
        ...state,
        errorInfo,
        showToaster,
        showPageError,
      };

    case Error.CLEAR_ERROR_INFO:
      return initialState;

    default:
      return state;
  }
};
