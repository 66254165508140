// Pages :: Dev

// Bootstrap Theme
import BootstrapThemePage from "./pages/bootstrap/BootstrapThemePage";

// Reactflow
import ReactflowSandboxPage from "./pages/reactflow/ReactflowSandboxPage";

// Layout
import LayoutPage from "./pages/layout/LayoutPage";

// Page Components
const devRoutes = [
  // Development Pages
  {
    path: "/dev/theme",
    element: <BootstrapThemePage />,
  },
  {
    path: "/dev/reactflow",
    element: <ReactflowSandboxPage />,
  },
  {
    path: "/dev/layout",
    element: <LayoutPage />,
  },
];

export default devRoutes;
