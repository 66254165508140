import { useMemo, useState } from "react";

// Components
import ExpressionInput from "../../../components/input-expression/ExpressionInput";

/**
 * Objective Function Card
 * @param {*} param0
 * @returns
 */
export default function ObjectiveFunctionCard({
  edges = [],
  parameters = [],
  objectiveInfo = {},
  setObjectiveInfo = () => {},
}) {
  // Objective Information
  const { name = "", isMinimize = "", costExpression = "" } = objectiveInfo || {};

  // State
  const [equatioInpVal, setEquationInpVal] = useState(costExpression);

  // Handle Change Function
  function handleEquationChange(e, newValue, newPlainTextValue) {
    // Updating input value state
    setEquationInpVal(e.target.value);

    // Updating state with plain text without any special characters
    setObjectiveInfo((prev) => ({ ...prev, costExpression: newPlainTextValue }));
  }

  function handleChange({ target }, key) {
    setObjectiveInfo((prev) => ({ ...prev, [key]: target.value }));
  }

  // use Memo
  const suggestions = useMemo(() => {
    // Vars
    const vars = [...edges, ...parameters];

    // Symbols
    const varSymbols = vars.map(({ symbol }) => ({ id: symbol, display: symbol }));
    return varSymbols;
  }, [edges, parameters]);

  return (
    <div className="card card-info">
      <div className="card-body">
        <div className="row">
          <div className="col-3">
            <label className="form-label">Name</label>
            <input value={name} className="form-control" onChange={(e) => handleChange(e, "name")} />
          </div>
          <div className="col-2">
            <label className="form-label">Objective</label>
            <select value={isMinimize} onChange={(e) => handleChange(e, "isMinimize")} className="form-select">
              <option value={true}>Minimize</option>
              <option value={false}>Maximize</option>
            </select>
          </div>
          <div className="col-7">
            <label className="form-label">Function</label>
            {/* Expression Input */}
            <ExpressionInput
              value={equatioInpVal}
              suggestions={suggestions}
              onChange={handleEquationChange}
              placeholder={"Objective function ..."}
            />

            <div className="pt-2">
              <b>Note : </b>
              Please provide space between operators. eg: (a + b)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
