import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { OptRunListActions } from "../../redux-slice/project/OptRunListSlice";
import { setErrorInfo } from "../../app/error/ErrorActions";

// APIs
import OptRunListAPI from "../../api/project/OptRunListAPI";

//
// Saga Functions
// ----------------------------------------------------------------------------

// Get Opt Run List
function* getOptRunList(action) {
  const { projectId } = action.payload;
  try {
    const { items, pagination } = yield OptRunListAPI.getOptRunList(projectId);

    // Dispatching Action
    yield put(OptRunListActions.getOptRunListSuccess({ optRunList: items, optRunListPagination: pagination }));
  } catch (error) {
    yield put(OptRunListActions.getOptRunListFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

//
// Export

export default function* root() {
  yield all([takeLatest(OptRunListActions.getOptRunList.type, getOptRunList)]);
}
