// Graph OptRun API URLs

//
const OptRunApiURL = {
  //
  CreateOptRunURL: "/api/v1/project/:projectId/opt/run",

  UpdateOptRunByIdURL: "/api/v1/project/:projectId/opt/:optRunId/run",

  GetOptRunByIdURL: "/api/v1/project/:projectId/opt/:optRunId",
  GetOptRunProblemByIdURL: "/api/v1/project/:projectId/opt/:optRunId/problem",

  //
  GetOptRunListURL: "/api/v1/project/:projectId/opt/search",
};

// Export
export default OptRunApiURL;
