import { createSlice } from "@reduxjs/toolkit";

// Intital state
const initialState = {
  // Paramters
  addEquationLoading: false,
  addEquationErr: "",

  // Update Equations state
  updateEquationLoading: false,
  updateEquationErr: "",

  // Delete Equations state
  deleteEquationLoading: false,
  deleteEquationErr: "",
};

// Equation Slice
const equationSlice = createSlice({
  // Name
  name: "Equation",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Add Equation
    addEquation: (state) => {
      state.addEquationLoading = true;
    },

    addEquationSuccess: (state) => {
      state.addEquationLoading = false;
      state.addEquationErr = "";
    },

    addEquationFailure: (state, action) => {
      state.addEquationLoading = false;
      state.addEquationErr = action.payload.error;
    },

    // Update Equation
    updateEquation: (state) => {
      state.updateEquationLoading = true;
    },

    updateEquationSuccess: (state) => {
      state.updateEquationLoading = false;
      state.updateEquationErr = "";
    },

    updateEquationFailure: (state, action) => {
      state.updateEquationLoading = false;
      state.updateEquationErr = action.payload.error;
    },

    // Delete Equation
    deleteEquation: (state) => {
      state.deleteEquationLoading = true;
    },

    deleteEquationSuccess: (state) => {
      state.deleteEquationLoading = false;
      state.deleteEquationErr = "";
    },

    deleteEquationFailure: (state, action) => {
      state.deleteEquationLoading = false;
      state.deleteEquationErr = action.payload.error;
    },
  },
});

// Reducer
export const EquationReducer = equationSlice.reducer;

// Actions
export const EquationActions = equationSlice.actions;
