import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { GraphActions } from "../../../redux-slice/graph/GraphSlice";
import { ProjectActions } from "../../../redux-slice/project/ProjectSlice";

// Components
import CardCollapsible from "../../../components/card-collapsible/CardCollapsible";

// Components :: Graph / Reactflow
import GraphConfig from "../../../reactflow/GraphConfig";
import GraphUtil from "../../../reactflow/GraphUtil";

//
import ProjectTopbar from "../ProjectTopbar";
import ProjectSidebarLeft from "../ProjectSidebarLeft";

//
// Constants
const NodeTypeNameMap = GraphConfig.NodeTypeNameMap;
const VarTypeNameMap = GraphConfig.VarTypeNameMap;

//
// Page Components
// ----------------------------------------------------------------------------

//
function VariableRow({ variable, entityType, onValueChange }) {
  //
  const { id, name, symbol, varType, value } = variable || {};
  const varTypeTxt = VarTypeNameMap[varType || "PARAMETER"];

  // onChange callback
  const onInputBlur = ({ target }) => {
    const newValue = target.value || "";

    // trigger callback
    onValueChange(entityType, id, newValue);
  };

  //
  return (
    <tr>
      <td className="pt-1">
        <span className="m-2">
          {name}
          <small className="ms-2 text-secondary fst-italic">{varTypeTxt}</small>
        </span>
      </td>
      <td className="pt-1">
        <span className="m-2">{symbol}</span>
      </td>
      <td>
        <input className="form-control form-control-sm" defaultValue={value} onBlur={onInputBlur} />
      </td>
    </tr>
  );
}

//
function VariableTable({ variables = [], entityType, onValueChange }) {
  //
  return (
    <div className="table-responsive">
      <table className="table table-compact">
        <thead>
          <tr>
            <th className="px-2 py-1">Variable</th>
            <th className="px-2 py-1">Symbol</th>
            <th className="px-2 py-1">Value</th>
          </tr>
        </thead>
        <tbody>
          {/** Variables */}
          {variables.map((variable, idx) => {
            //
            return (
              <VariableRow
                key={`key-var-${idx}`} //
                variable={variable}
                entityType={entityType}
                onValueChange={onValueChange}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

/**
 * Page
 */
export default function ProjectDataPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const params = useParams();
  const { projectId } = params;
  const graphId = projectId; // graphId is just "projectId"

  // Dispatch API calls
  useEffect(() => {
    dispatch(ProjectActions.getProjectInfo({ projectId }));
    dispatch(GraphActions.getGraphData({ graphId }));
  }, [projectId, graphId, dispatch]);

  //
  // Selector State
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const graphData = useSelector((state) => state.graph.graphData);
  const graphDataLoading = useSelector((state) => state.graph.graphDataLoading);

  //
  // Graph Data
  const { nodes = [], edges = [] } = GraphUtil.toReactFlowFormat(graphId, graphData);
  const { info: entityInfo = {}, parameters = [] } = graphData || {};

  const { edgeData = {}, parameterData = {} } = entityInfo;

  //
  // Edge Variables
  const edgeVars = React.useMemo(() => {
    const vars = edges.map((e) => {
      const { id, data } = e;
      const value = edgeData[id] || "";

      return {
        id,
        ...data,
        value,
      };
    });

    return vars;
  }, [edges, edgeData]);

  // Parameter Variables
  const paramVars = React.useMemo(() => {
    const vars = parameters.map((p = {}) => {
      const value = parameterData[p.id] || "";

      return {
        ...p,
        value,
      };
    });

    return vars;
  }, [parameters, parameterData]);

  //
  //
  // Functions

  const onEntityValueChange = (entityType, entityId, value) => {
    // Dispatch Graph Entity Update Action
  };

  //
  return (
    <>
      {/* Topbar */}
      <ProjectTopbar projectInfo={projectInfo} />

      {/* Sidebar Left */}
      <ProjectSidebarLeft projectInfo={projectInfo} selection={"data"} />

      {/* Page Content */}
      <div className="main-cont position-right-0">
        <div className="content-wrapper">
          {/** */}
          <div className="page-content">
            <div className="row">
              <div className="col-6">
                {/** Parameters */}
                <h3 className="m-1 mb-3">
                  <i className="fas fa-square-xmark"></i> Parameters
                </h3>

                <VariableTable variables={paramVars} entityType="parameter" onValueChange={onEntityValueChange} />
              </div>
              <div className="col-6">
                {/** Edges */}
                <h3 className="m-1 mb-3">
                  <i className="fas fa-square-xmark"></i> Variables
                </h3>

                <VariableTable variables={edgeVars} entityType="edge" onValueChange={onEntityValueChange} />
              </div>
            </div>

            {/** */}
          </div>
        </div>
      </div>
    </>
  );
}
