import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { ParameterActions } from "../../redux-slice/graph/ParameterSlice";
import { setErrorInfo } from "../../app/error/ErrorActions";

// APIs
import ParameterAPI from "../../api/graph/ParameterAPI";

// Constants
import GraphConfig from "../../reactflow/GraphConfig";

//
const NODE_PARAMS = GraphConfig.NodeDataProp.PARAMETERS;

//
// Saga Functions
// ----------------------------------------------------------------------------

//
// Node : Parameter

// Create Parameter
export function* createParameter(action) {
  const { parameterObj = {}, updateNodeMathData } = action.payload;

  try {
    const { graphId = "" } = parameterObj;
    const newParameter = yield ParameterAPI.createParameter(graphId, parameterObj);

    // Callback
    updateNodeMathData(NODE_PARAMS, newParameter, false);

    yield put(ParameterActions.createParameterSuccess({ parameterObj: newParameter }));
  } catch (error) {
    yield put(ParameterActions.createParameterFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update Parameter
export function* updateParameter(action) {
  const { parameterId, parameterObj, updateNodeMathData } = action.payload;

  try {
    const { graphId } = parameterObj;
    yield ParameterAPI.updateParameter(graphId, parameterId, parameterObj);

    // Callback
    updateNodeMathData(NODE_PARAMS, parameterObj, false);

    yield put(ParameterActions.updateParameterSuccess({ parameterObj }));
  } catch (error) {
    yield put(ParameterActions.updateParameterFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

export function* deleteParameter(action) {
  const { parameterObj, updateNodeMathData } = action.payload;

  try {
    const { graphId, id: parameterId } = parameterObj;
    yield ParameterAPI.deleteParameter(graphId, parameterId);

    // Callback
    updateNodeMathData(NODE_PARAMS, parameterObj, true);

    yield put(ParameterActions.deleteParameterSuccess());
  } catch (error) {
    yield put(ParameterActions.deleteParameterFailure());

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

//
// Export

export default function* root() {
  yield all([
    //
    takeLatest(ParameterActions.createParameter.type, createParameter),
    takeLatest(ParameterActions.updateParameter.type, updateParameter),
    takeLatest(ParameterActions.deleteParameter.type, deleteParameter),

    //
  ]);
}
